import * as React from 'react';
import { DialogActions, Paper, Checkbox, DialogContent, FormControlLabel } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Tee, isSkinsScoring, Competition, getHolesRange, EventBase } from '../../../types/EventTypes';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import ButtonBar from '../../../common/components/ButtonBar';
import AppButton from '../../../common/components/AppButton';
import { ItemS, FlexGrid } from '../../../common/Misc';
import { styles } from '../../../styles';

interface HoleProps {
    par?: number;
    hole: number;
    selected: boolean;
    disabled: boolean;
}

type SelectFunc = (hole: number, checked: boolean) => void;

class HoleItemImpl extends React.Component<WithStyles<typeof styles> & { holeProps: HoleProps } & { onSelectChange: SelectFunc }> {
    GridContent = () => {
        const { classes, onSelectChange } = this.props;
        const { hole, par, selected, disabled } = this.props.holeProps;
        return (
            <Paper className={classes.holePaper}>
                <FormControlLabel style={{ width: '100%' }}
                    classes={{ label: classes.labelOverflow }}
                    control={<Checkbox color="secondary" onChange={(e, v) => onSelectChange(hole, v)} checked={selected} disabled={disabled} style={{ paddingLeft: 6 }} />}
                    label={`Hole ${hole + 1} ${par ? ' Par ' + par : ''}`}
                />
            </Paper>
        );
    }
    render() {
        return (
            <ItemS><this.GridContent /></ItemS>
        );
    }
}

const HoleItem = withStyles(styles)(HoleItemImpl);

export function CheckBoxGrid(props: { holes: Array<HoleProps>, onSelectChange: SelectFunc }) {
    const { holes, onSelectChange } = props;
    return (
        <FlexGrid spacing={2}>
            {holes.map(holeProps => <HoleItem key={holeProps.hole} holeProps={holeProps} onSelectChange={onSelectChange} />)}
        </FlexGrid>
    );
}

interface Props {
    event: EventBase;
    competition?: Competition;
    selectedHoles?: Array<number>;
    tee?: Tee | null;
    handleOk: (selectedHoles: Array<number>) => void;
    handleCancel: () => void;
}

interface State {
    holes: Array<HoleProps>;
}

export default class SelectHolesDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const holes = new Array<HoleProps>();
        const { event, competition, selectedHoles, tee } = props;
        const holesRange = getHolesRange(event.holesType);
        for (let hole = holesRange.first; hole < holesRange.last; hole++) {
            const par = tee && tee.par ? tee.par[hole % tee.par.length] : undefined;
            let disabled = false;
            if (par && competition) {
                if ((competition.scoring.format === 'longest_drive' && par < 4) ||
                    (competition.scoring.format === 'closest_to_the_pin' && par > 3)) {
                    disabled = true;
                }
            }
            holes.push({
                hole,
                par: tee && tee.par ? tee.par[hole % tee.par.length] : undefined,
                selected:
                    !disabled && competition?.scoring.holes ? competition.scoring.holes.indexOf(hole) >= 0 :
                        competition ? isSkinsScoring(competition.scoring) :
                            selectedHoles ? selectedHoles.indexOf(hole) >= 0 : false,
                disabled
            });
        }
        this.state = { holes };
    }

    private onSelectChange = (hole: number, selected: boolean) => {
        const holes = this.state.holes;
        const hp = holes.find(h => h.hole === hole);
        if (hp) {
            hp.selected = selected;
        }
        this.setState({ holes });
    }

    private close = () => this.props.handleCancel();
    private selectAll = () => this.select(true);
    private selectNone = () => this.select(false);

    private select = (selected: boolean) => {
        const holes = this.state.holes;
        holes.forEach(h => {
            if (!h.disabled) {
                h.selected = selected;
            }
        });
        this.setState({ holes });
    }

    private onOk = () => {
        const holes = this.state.holes;
        this.props.handleOk(holes.filter(h => h.selected && !h.disabled).map(h => h.hole));
    }

    render() {
        const { holes } = this.state;
        return (
            <React.Fragment>
                <XSMobileDialog open={true} onClose={this.close}>
                    <DialogAppBar label="Select holes" close={this.close} />
                    <DialogContent>
                        <ButtonBar>
                            <AppButton color="secondary" onClick={this.selectAll}>Select all</AppButton>
                            <AppButton color="info" onClick={this.selectNone}>Select none</AppButton>
                        </ButtonBar>
                        <CheckBoxGrid holes={holes} onSelectChange={this.onSelectChange} />
                    </DialogContent>
                    <DialogActions>
                        <AppButton color="info" onClick={this.close}>Cancel</AppButton>
                        <AppButton color="secondary" onClick={this.onOk}>Save</AppButton>
                    </DialogActions>
                </XSMobileDialog>
            </React.Fragment>
        );
    }
}
