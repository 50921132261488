import { createTheme, SxProps, Theme } from '@mui/material/styles';

export class AppColors {
    static readonly white = '#ffffff';
    static readonly red = '#ff0000';
    static readonly bluish = '#5893C7';
    static readonly defaultBk = 'white'
    static readonly primary = '#4595c8';
    static readonly primaryLight = '#55B8F7';
    static readonly secondary = '#005b88';
    static readonly secondaryLight = '#0090D7';
    static readonly hoverColor = '#E0ECF5';
    static readonly hoverColorInv = '#4DA7E0';
    static readonly reportedColor = '#CEE9D1';
    static readonly webWarning = '#E6B614';
    static readonly errorColor = `#D32F2F`;
    static readonly webBlack = '#212121';
    static readonly webBlue100 = '#e2ecf5';
    static readonly webBlue200 = '#ACCDEA';
    static readonly webBlue300 = '#77A8D2';
    static readonly webBlue500 = '#225987';
    static readonly webGrey = '#757575';
    static readonly webGreyLight = '#EEEEEE';
    static readonly webGrey100 = '#F5F5F5';
    static readonly webGrey200 = '#C4C4C4';
    static readonly webGrey300 = '#949494';
    static readonly webSuccess = '#50BD80';
    static readonly webError = '#E5543D';
    static readonly defaultTextColor = AppColors.secondary;
    static readonly primaryTextColor = AppColors.white;
    static readonly shadowedTextColor = AppColors.webBlue100;
    static readonly descriptionBkColor = AppColors.webBlue100;
}

declare module '@mui/material/styles' {
    interface DefaultTheme extends Theme { }
}

export const topTextStyle: SxProps = {
    fontSize: 16,
    lineHeight: '24px',
    color: AppColors.webBlack
};

export const bottomTextStyle: SxProps = {
    fontSize: 14,
    lineHeight: '21px',
    color: AppColors.webGrey
};

export const topTextStyleSm: SxProps = {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: AppColors.webBlack
};

export const bottomTextStyleSm: SxProps = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    color: AppColors.webGrey
};

export const theme = createTheme({
    typography: {
        fontFamily: '"poppins", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    palette: {
        primary: {
            main: AppColors.primary
        },
        secondary: {
            main: AppColors.secondary
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 450,
            md: 600,
            lg: 800,
            xl: 1200
        }
    },
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    boxShadow: 'none'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'contained' },
                    style: {
                        height: 32,
                        minHeight: 32,
                        minWidth: 60,
                        borderRadius: 24,
                        paddingTop: 4,
                        paddingBottom: 4,
                        whiteSpace: 'nowrap',
                        boxShadow: 'none',
                        textTransform: 'none',
                        color: AppColors.secondary,
                        backgroundColor: 'inherit',
                        borderColor: AppColors.secondary,
                        borderWidth: 1,
                        borderStyle: 'solid',
                    }
                }, {
                    props: { color: 'secondary' },
                    style: {
                        color: AppColors.white,
                        backgroundColor: AppColors.secondary
                    }
                }, {
                    props: { color: 'primary' },
                    style: {
                        color: AppColors.white,
                        backgroundColor: AppColors.primary
                    }
                }, {
                    props: { variant: 'text', color: 'primary' },
                    style: {
                        color: AppColors.secondary,
                        borderColor: AppColors.secondary,
                    }
                }, {
                    props: { variant: 'contained', color: 'primary' },
                    style: {
                        borderColor: AppColors.primary,
                    }
                }, {
                    props: { variant: 'text', color: 'secondary' },
                    style: {
                        borderColor: AppColors.secondary,
                    }
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        color: 'rgba(0, 0, 0, 0.87)',
                        '&:hover': {
                            backgroundColor: AppColors.webGrey100,
                            borderColor: 'lightgray'
                        },
                        borderColor: 'lightgray'
                    }
                }, {
                    props: { color: 'info' }, // was default
                    style: {
                        '&:hover': {
                            backgroundColor: 'lightgray'
                        },
                        color: AppColors.secondary
                    }
                }, {
                    props: { color: 'secondary', variant: 'outlined' },
                    style: {
                        color: AppColors.secondary,
                        borderColor: AppColors.primary,
                        '&:hover': {
                            borderColor: AppColors.secondary,
                        },
                        backgroundColor: 'inherit'
                    }
                }, {
                    props: { color: 'info', variant: 'outlined' },
                    style: {
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        color: 'rgba(0, 0, 0, 0.87)'
                    }
                }
            ]
        },
        MuiPaper: {
            styleOverrides: {
                root: { backgroundImage: 'unset' }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                    width: 30,
                    height: 30,
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                    paddingRight: 4,
                    width: 22,
                    height: 22,
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    textAlign: 'left',
                    transform: 'translate(0, 1.5px) scale(0.75)',
                    transformOrigin: 'top left',
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: -8,
                    marginRight: 16,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthSm: {
                    maxWidth: 600
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 16
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid lightgray'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minWidth: 160
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    '&:hover': {
                        backgroundColor: AppColors.hoverColor
                    }
                },
                gutters: {
                    paddingRight: 16,
                    paddingLeft: 16,
                    '@media (max-width: 600px)': {
                        paddingRight: 8,
                        paddingLeft: 8,
                    }
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: AppColors.hoverColor
                    },
                },
                gutters: {
                    paddingRight: 16,
                    paddingLeft: 16,
                    '@media (max-width: 600px)': {
                        paddingRight: 8,
                        paddingLeft: 8,
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: '0.875rem',
                    lineHeight: '1.46429em'
                },
                body2: {
                    fontSize: '0.775rem',
                    lineHeight: '1.46429em'
                },
                paragraph: {
                    fontSize: '1rem',
                    lineHeight: '1.46429em'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal'
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                colorError: {
                    backgroundColor: AppColors.webWarning
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    "&:not(.Mui-disabled):hover::before": {
                        borderColor: AppColors.primary
                    },
                },
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: {
                    width: '100%',
                    padding: 0
                }
            }
        },
    },
});
