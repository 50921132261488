import * as React from 'react';
import {
    Event, FacilitiesGroup, Facility, Gender,
    ResultStatus, SimpleCourse, Tee, isCompoundCourse, isCompoundFacility,
    HOLES_18, HOLES_9, HOLES_9_9, HOLES_TYPES, HOLES_TYPES_COMPOUND, HOLES_TYPES_NINES, HOLES_NAMES, Course, HolesType, CourseResponse, getCourseName
} from 'src/types/EventTypes';
import {
    ClickAwayListener, Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Popper,
    RadioGroup, Select, SxProps, TextField, Typography, useMediaQuery, useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { ProgressRadio, Spacing } from 'src/common/Misc';
import { AppColors } from 'src/main/Theme';
import { getCourse, getHolesType, handleFacilityMissingTees, iCourse, oCourse } from '../Courses';
import { processEnterKey } from 'src/util/react_utils';
import { InfoIconTooltip } from 'src/common/components/InfoToolTip';
import EditTeeDialog from '../tabs/common/EditTeeDialog';
import AppButton from 'src/common/components/AppButton';
import LocationFacilitiesProvider from '../tabs/settings/general/course/providers/LocationFacilitiesProvider';
import AddressFacilitiesProvider from '../tabs/settings/general/course/providers/AddressFacilitiesProvider';
import RecentProvider, { saveToRecent } from '../tabs/settings/general/course/providers/RecentProvider';
import TeesProvider from '../tabs/common/TeesProvider';
import { FacilityList } from '../tabs/settings/general/course/FacilityView';
import { CourseViewHeaderLong, CourseViewHeaderShort, CourseViewLong, CourseViewShort } from '../tabs/settings/general/course/CourseSelectionDialog';
import { toHolesType, getTeeName, getGender, compareTee } from '../../scoring/handicap';
import { cleanObj } from "../../util/utility";
import { defaultTeeExplanation } from 'src/util/strings';

const addTeeInfoLater = 'Add tee info later';
const teesNotFound = 'Tees not found';
const NEW_EVENT_ELEM_WIDTH = 400;

const blueText: SxProps = {
    color: AppColors.webBlue500,
    fontSize: '14px',
    lineHeight: '21px'
};

interface FacilitiesResult {
    loadResult?: string;
    loadStatus?: ResultStatus;
    facilities?: Array<Facility>;
}

type FacilityListWrappedProps = {
    onSelect: (facility: Facility) => void,
    facilitiesGroups: Array<FacilitiesGroup>,
    userId: string,
    onClickAway: () => void
};

const FacilityListWrapped = ({ onSelect, facilitiesGroups, userId, onClickAway }: FacilityListWrappedProps) => {
    const moreThanLg = useMediaQuery(useTheme().breakpoints.up('lg'));
    return (
        <Paper style={{ width: NEW_EVENT_ELEM_WIDTH, maxHeight: 500, overflow: 'auto', marginTop: 10 }}>
            <ClickAwayListener onClickAway={() => { if (moreThanLg) onClickAway() }}>
                <FacilityList userId={userId} facilitiesGroups={facilitiesGroups} onSelect={onSelect} />
            </ClickAwayListener>
        </Paper>
    );
};

const EditTeeButton = (props: { facility: SimpleCourse, isCompound: boolean, btnStyle: React.CSSProperties, selectFacility: (facility: SimpleCourse) => void }) => {
    const { facility, isCompound, btnStyle, selectFacility } = props;
    return (
        <AppButton color="secondary" style={btnStyle} onClick={() => selectFacility(facility)}>
            <AddSharpIcon />
            <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                Add tee{`${isCompound && facility.name ? ` (${facility.name})` : ''}`}
            </Typography>
        </AppButton>
    );
}

type Props = {
    event: Event;
    visible: boolean;
    coursesActivated?: boolean;
    course?: Course;
    holesType?: HolesType;
    defaultMenTee?: string;
    defaultWomenTee?: string;
    courseUpdated: (course?: Course, holesType?: HolesType, teeMen?: Tee, teeWomen?: Tee) => void;
}

type State = {
    tees: Array<Tee>;
    showFacilities?: boolean;
    editingTee?: Tee;
    course?: Course;
    holesType?: HolesType;
    searchAddress: string;
    foundAddress: string;
    courseAddress?: string;
    defaultMenTee?: string;
    defaultWomenTee?: string;
    selectedIn?: SimpleCourse;
    selectedOut?: SimpleCourse;
    notCombinedTees?: Array<Tee>;
    selectedFacility?: Facility;
    nearbyResult: FacilitiesResult;
    foundResult: FacilitiesResult;
    recentResult: FacilitiesResult;
    teesLoadStatus?: ResultStatus;
    teeSelectionCourse?: SimpleCourse;
}

export class CourseSetupPage extends React.Component<Props, State> {
    state: State = {
        tees: [],
        course: this.props.course,
        holesType: this.props.holesType,
        defaultMenTee: this.props.defaultMenTee,
        defaultWomenTee: this.props.defaultWomenTee,
        searchAddress: '',
        foundAddress: '',
        nearbyResult: {},
        foundResult: {},
        recentResult: {},
    }

    private readonly coursesLoader: React.RefObject<AddressFacilitiesProvider>;
    private readonly teesLoader: React.RefObject<TeesProvider>;
    private readonly inputRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.coursesLoader = React.createRef();
        this.teesLoader = React.createRef();
        this.inputRef = React.createRef();
    }

    public closePopups = () => {
        const { showFacilities } = this.state;
        if (showFacilities) {
            this.setState({ showFacilities: false });
            return true;
        }
        return false;
    }

    public courseIsMissing = () => {
        const { course, holesType, selectedFacility, selectedOut, selectedIn } = this.state;
        const isCompound = isCompoundFacility(selectedFacility);
        return !course ||
            (isCompound && holesType !== HOLES_18 && !selectedIn) ||
            (isCompound && holesType === HOLES_18 && !(selectedOut && selectedIn));
    };

    private setAsOut = (course: SimpleCourse) => this.setState({ selectedOut: course }, this.courseUpdated);

    private setAsIn = (course: SimpleCourse) => this.setState({ selectedIn: course }, this.courseUpdated);

    private loadTees = () => this.teesLoader.current?.loadTees();

    private handleFacilitySelect = (selectedFacility: Facility) => {
        const { event } = this.props;
        saveToRecent(selectedFacility, event.userId);
        const course = getCourse(selectedFacility);
        this.setState({
            course, holesType: undefined, showFacilities: false, selectedFacility, courseAddress: undefined,
            selectedOut: undefined, selectedIn: undefined, tees: [], defaultMenTee: undefined, defaultWomenTee: undefined
        }, this.courseUpdated);
        if (course) {
            const courseAddress = selectedFacility.city?.formattedString;
            this.setState({ courseAddress }, this.loadTees);
        }
    }

    private handleHolesChange = (val: string) => {
        const holesType = toHolesType(val);
        this.setState({ holesType, selectedIn: undefined, selectedOut: undefined }, this.courseUpdated);
    }

    private courseUpdated = () => {
        const { courseUpdated } = this.props;
        const { course, holesType, tees, defaultMenTee, defaultWomenTee, selectedIn, selectedOut } = this.state;
        if (course && 'facilityId' in course && (selectedIn || selectedOut)) {
            if (selectedIn !== course.inCourse) {
                course.inCourse = selectedIn;
            }
            if (selectedOut !== course.outCourse) {
                course.outCourse = selectedOut;
            }
        }
        courseUpdated(cleanObj(course), holesType, tees.find(tee => tee.id === defaultMenTee), tees.find(tee => tee.id === defaultWomenTee));
    }

    private handleCourseSearch = (_?: React.MouseEvent<any>) => {
        this.setState({ showFacilities: true });
        this.coursesLoader.current?.load();
    }

    private handleOpenFacilities = () => {
        this.setState({ showFacilities: true });
    }

    private handleCloseEditTee = (changed: boolean, id: string, gender: Gender) => {
        this.setState({ editingTee: undefined, teeSelectionCourse: undefined });
        if (changed) {
            if (gender === 'female') {
                this.setState({ defaultWomenTee: id }, this.courseUpdated);
            } else {
                this.setState({ defaultMenTee: id }, this.courseUpdated);
            }
            this.loadTees();
        }
    };

    private setEditingTee = (gender: Gender, simpleCourse?: SimpleCourse) => this.setState({
        editingTee: {
            name: '', gender,
            facilityId: simpleCourse?.id,
            facilityName: simpleCourse?.name
        } as Tee,
        teeSelectionCourse: simpleCourse
    });

    private onNearestResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const nearbyResult: FacilitiesResult = {
            loadStatus,
            loadResult: err ?? '',
            facilities: courseResponse?.facilities
        };
        this.setState({ nearbyResult });
    }

    private onFoundResult = (loadStatus: ResultStatus, courseResponse?: CourseResponse, err?: any) => {
        const foundAddress = courseResponse?.city.formattedString ?? '';
        const foundResult: FacilitiesResult = {
            loadStatus,
            loadResult: err ?? '',
            facilities: courseResponse?.facilities
        };
        this.setState({ foundResult, foundAddress });
    }

    private onRecentResult = (loadStatus: ResultStatus, recentFacilities?: Array<Facility>) => {
        const recentResult: FacilitiesResult = {
            loadStatus,
            loadResult: recentFacilities && recentFacilities.length > 0 ? '' : 'No recent courses yet',
            facilities: recentFacilities
        };
        this.setState({ recentResult });
    }

    private teesLoaded = (teesLoadStatus: ResultStatus, tees?: Array<Tee>, notCombinedTees?: Array<Tee>) => {
        this.setState({ tees: tees || [], teesLoadStatus });
        const { course, selectedFacility } = this.state;
        if (!course || !selectedFacility || !tees) {
            return;
        }
        if (tees.length === 0) {
            handleFacilityMissingTees(selectedFacility, course);
        } else {
            const selectedIn = iCourse(course);
            const selectedOut = oCourse(course);
            const defaultMenTee = tees.find(tee => getGender(tee) === 'male')?.id;
            const defaultWomenTee = tees.find(tee => getGender(tee) === 'female')?.id;
            const holesType = getHolesType(selectedFacility, tees);
            this.setState({ holesType, tees, selectedOut, selectedIn, defaultMenTee, defaultWomenTee, notCombinedTees }, this.courseUpdated);
        }
    }

    render() {
        const { event, visible, coursesActivated } = this.props;
        const {
            showFacilities, nearbyResult, recentResult, foundResult, searchAddress, foundAddress, courseAddress,
            defaultMenTee, defaultWomenTee, editingTee, teesLoadStatus, selectedFacility, selectedOut, selectedIn,
            teeSelectionCourse, notCombinedTees, course, holesType
        } = this.state;
        const id = showFacilities ? 'courses-popper' : undefined;
        const endAdornment = (onClk: (e: React.MouseEvent<any>) => void) => <InputAdornment position="end">
            <IconButton tabIndex={-1} onClick={onClk} size="large"><SearchIcon /></IconButton>
        </InputAdornment>;
        const isCompound = isCompoundFacility(selectedFacility);
        const compoundShort = isCompound && holesType !== HOLES_18;
        const orderSimple = ['North', 'South', 'East'];
        const orderTemp = orderSimple.reduce((c, v, i) => Object.assign(c, { [v]: i }), {} as any);
        const isNineCourse = !isCompound && (holesType === HOLES_9 || holesType === HOLES_9_9);
        const holes = isNineCourse ? HOLES_TYPES_NINES : isCompound ? HOLES_TYPES_COMPOUND : HOLES_TYPES;
        const hasMen = event.eventGender === 'both' || event.eventGender === 'men' || !event.eventGender;
        const hasWomen = event.eventGender === 'both' || event.eventGender === 'women' || !event.eventGender;
        const tees = holesType === HOLES_9 && isCompound && notCombinedTees ? notCombinedTees : this.state.tees;
        const menTees = tees.filter(tee => getGender(tee) === 'male').sort(compareTee);
        const womenTees = tees.filter(tee => getGender(tee) === 'female').sort(compareTee);
        const disabled = !course || teesLoadStatus !== 'ok';
        const facilitiesGroups: Array<FacilitiesGroup> = [];
        const errorMen = menTees.length > 0 ? '' : teesNotFound;
        const errorWomen = womenTees.length > 0 ? '' : teesNotFound;
        const menTeesError = !disabled && Boolean(errorMen);
        const womenTeesError = !disabled && Boolean(errorWomen);
        if (showFacilities) {
            facilitiesGroups.push({ name: foundAddress, ...foundResult });
            if (!foundResult.facilities?.length) {
                facilitiesGroups.push({ name: 'Previous courses', ...recentResult });
                if (nearbyResult.loadStatus !== 'error') {
                    facilitiesGroups.push({ name: 'Near you', ...nearbyResult });
                }
            }
        }
        const courseName = getCourseName(course);
        //const courseName = getFacilityName(selectedFacility, selectedOut, selectedIn);
        const singleFacility = course && !isCompoundCourse(course) ? course :
            (selectedIn && selectedIn.id === selectedOut?.id) ? selectedIn : undefined;
        const errorAndNotDisabled = !disabled && (errorMen || errorWomen);
        return <>
            {visible && <>
                <FormControl variant="standard" margin="dense" fullWidth>
                    <TextField
                        id="course"
                        variant="standard"
                        ref={this.inputRef}
                        label="Select course"
                        value={showFacilities ? searchAddress : courseName}
                        helperText={showFacilities ? undefined : courseAddress}
                        placeholder="Search by city or course name"
                        style={{ width: NEW_EVENT_ELEM_WIDTH }}
                        onKeyDown={e => processEnterKey(e, this.handleCourseSearch)}
                        onChange={e => this.setState({ searchAddress: e.target.value })}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: { width: '100%' },
                            autoComplete: 'off',
                            endAdornment: endAdornment(e => {
                                e.stopPropagation();
                                return showFacilities ? this.handleCourseSearch() : this.handleOpenFacilities();
                            }),
                            onClick: e => {
                                if (!showFacilities) {
                                    this.handleOpenFacilities();
                                } else {
                                    e.stopPropagation();
                                }
                            }
                        }}
                    />
                </FormControl>
                <Spacing />
                <Typography gutterBottom>Holes</Typography>
                <FormControl
                    fullWidth
                    variant="standard"
                    disabled={disabled}
                    style={{ flexDirection: 'column' }}>
                    <RadioGroup value={String(holesType)} style={{ flexDirection: 'row' }} onChange={e => this.handleHolesChange(e.target.value)}>
                        {holes.map(ht => <ProgressRadio key={ht} value={String(ht)} label={HOLES_NAMES[ht]} />)}
                    </RadioGroup>
                    {isCompound && <div>
                        <Spacing />
                        {compoundShort ? <CourseViewHeaderShort /> : <CourseViewHeaderLong />}
                        {compoundShort ?
                            selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course =>
                                <CourseViewShort key={course.id} course={course}
                                    outNine={(selectedOut?.id ?? '') === course.id}
                                    inNine={(selectedIn?.id ?? '') === course.id}
                                    setAsOut={this.setAsOut} setAsIn={this.setAsIn} />) :
                            selectedFacility!.courses.sort((a, b) => orderTemp[a.name] - orderTemp[b.name]).map(course =>
                                <CourseViewLong key={course.id} course={course}
                                    outNine={(selectedOut?.id ?? '') === course.id}
                                    inNine={(selectedIn?.id ?? '') === course.id}
                                    setAsOut={this.setAsOut} setAsIn={this.setAsIn} />)}
                    </div>}
                </FormControl>
                <Spacing />
                <Typography>
                    Default tees
                    <InfoIconTooltip
                        style={{ marginLeft: '5px' }} title={defaultTeeExplanation}
                        placement="right" offset1={-8} offset2={1} leaveTouchDelay={4000} />
                </Typography>
                <Spacing />
                {hasMen && <FormControl
                    variant="standard"
                    disabled={disabled || Boolean(errorMen)}
                    style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 - 8, marginRight: 8 }}>
                    <InputLabel id="select-tees-men-label" shrink>Men</InputLabel>
                    <Select
                        IconComponent={menTeesError ? () => null : undefined}
                        variant="standard"
                        labelId="select-tees-men-label"
                        id="select-tees-men"
                        label="men"
                        value={menTeesError ? addTeeInfoLater : menTees.find(t => t.id === defaultMenTee)?.id ?? ''}
                        onChange={e => {
                            if (e.target.value) {
                                this.setState({ defaultMenTee: e.target.value }, this.courseUpdated);
                            }
                        }}>
                        {menTees.map(tee => <MenuItem key={tee.id} value={tee.id}>{getTeeName(tee, holesType)}</MenuItem>)}
                        <Divider variant="middle" />
                        {singleFacility &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('male', singleFacility)}>
                                + Add tee
                            </MenuItem>}
                        {selectedIn && selectedIn.id !== selectedOut?.id &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('male', selectedIn)}>
                                + Add tee{` (${selectedIn.name})`}
                            </MenuItem>}
                        {selectedOut && selectedOut.id !== selectedIn?.id &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('male', selectedOut)}>
                                + Add tee{` (${selectedOut.name})`}
                            </MenuItem>}
                        {menTeesError && <MenuItem sx={blueText} value={addTeeInfoLater}>{addTeeInfoLater}</MenuItem>}
                    </Select>
                    {menTeesError && <FormHelperText error={true}>{errorMen}</FormHelperText>}
                </FormControl>}
                {hasWomen && <FormControl
                    variant="standard"
                    disabled={disabled || Boolean(errorWomen)}
                    style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 }}>
                    <InputLabel id="select-tees-women-label" shrink>Women</InputLabel>
                    <Select
                        IconComponent={womenTeesError ? () => null : undefined}
                        variant="standard"
                        labelId="select-tees-women-label"
                        id="select-tees-women"
                        label="women"
                        value={womenTeesError ? addTeeInfoLater : womenTees.find(t => t.id === defaultWomenTee)?.id ?? ''}
                        onChange={e => {
                            if (e.target.value) {
                                this.setState({ defaultWomenTee: e.target.value }, this.courseUpdated);
                            }
                        }}>
                        {womenTees.map(tee => <MenuItem key={tee.id} value={tee.id}>{getTeeName(tee, holesType)}</MenuItem>)}
                        <Divider variant="middle" />
                        {singleFacility &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('female', singleFacility)}>
                                + Add tee
                            </MenuItem>}
                        {selectedIn && selectedIn.id !== selectedOut?.id &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('female', selectedIn)}>
                                + Add tee{` (${selectedIn.name})`}
                            </MenuItem>}
                        {selectedOut && selectedOut.id !== selectedIn?.id &&
                            <MenuItem
                                sx={blueText} value=""
                                onClick={() => this.setEditingTee('female', selectedOut)}>
                                + Add tee{` (${selectedOut.name})`}
                            </MenuItem>}
                        {womenTeesError && <MenuItem sx={blueText} value={addTeeInfoLater}>{addTeeInfoLater}</MenuItem>}
                    </Select>
                    {womenTeesError && <FormHelperText error={true}>{errorWomen}</FormHelperText>}
                </FormControl>}
                {errorAndNotDisabled && (singleFacility ||
                    (holesType === HOLES_9 && isCompound && (selectedIn || selectedOut))) &&
                    <FormControl
                        variant="standard"
                        style={{ width: '45%', minWidth: 90, maxWidth: NEW_EVENT_ELEM_WIDTH * 3 / 8 }}>
                        <EditTeeButton
                            facility={(singleFacility ?? selectedIn ?? selectedOut)!}
                            selectFacility={facility => this.setEditingTee('', facility)}
                            isCompound={isCompound}
                            btnStyle={{ marginTop: 16 }}
                        />
                    </FormControl>}
                {errorAndNotDisabled && selectedIn && selectedOut && selectedIn.id !== selectedOut.id &&
                    <FormControl
                        variant="standard"
                        style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                        <EditTeeButton
                            facility={selectedIn} isCompound={isCompound}
                            selectFacility={facility => this.setEditingTee('', facility)}
                            btnStyle={{ marginTop: 4, maxWidth: NEW_EVENT_ELEM_WIDTH / 2 }}
                        />
                        <EditTeeButton
                            facility={selectedOut}
                            isCompound={isCompound}
                            selectFacility={facility => this.setEditingTee('', facility)}
                            btnStyle={{ marginTop: 4, marginLeft: 10, maxWidth: NEW_EVENT_ELEM_WIDTH }}
                        />
                    </FormControl>}
                <Popper
                    id={id}
                    sx={{ zIndex: 1500 }}
                    placement='bottom-start'
                    open={Boolean(showFacilities) && Boolean(this.inputRef.current)}
                    anchorEl={this.inputRef.current}>
                    <FacilityListWrapped
                        onSelect={this.handleFacilitySelect}
                        facilitiesGroups={facilitiesGroups}
                        userId={event.userId}
                        onClickAway={() => this.setState({ showFacilities: false })}
                    />
                </Popper>
                {editingTee && teeSelectionCourse && <EditTeeDialog
                    noGenderRequired tee={editingTee} facility={teeSelectionCourse} showFacilityName={isCompound}
                    event={event} tees={(isCompound && notCombinedTees?.length) ? notCombinedTees : tees}
                    isNine={isCompound} close={this.handleCloseEditTee} genderFixed={Boolean(editingTee.gender)}
                />}
            </>}
            {coursesActivated && <>
                <LocationFacilitiesProvider withTeesOnly onResult={this.onNearestResult} />
                <AddressFacilitiesProvider withTeesOnly ref={this.coursesLoader} address={searchAddress} onResult={this.onFoundResult} />
                <RecentProvider userId={event.userId} onResult={this.onRecentResult} />
                {course && <TeesProvider includeNotCombined event={event} course={course} onResult={this.teesLoaded} ref={this.teesLoader} />}
            </>}
        </>;
    }
}
