import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Event, EventData } from '../../../types/EventTypes';
import GeneralEventSettings from './general/GeneralEventSettings';
import PortalSettings from './portal/PortalSettings';
import LinkTabs, { Tab } from '../common/LinkTabs';
import { styles } from '../../../styles';

type TabType = 'general' | 'portal';

interface SettingsProperties {
    event: Event;
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

function SettingsTab(props: SettingsProperties & WithStyles<typeof styles>) {
    const tabs: Array<Tab<TabType>> = [
        {
            id: 'general',
            label: 'GENERAL',
            node: <GeneralEventSettings event={props.event} eventData={props.eventData} />
        },
        {
            id: 'portal',
            label: 'EVENT SITE',
            node: <PortalSettings itemId={props.event.id} />
        }
    ];
    return <LinkTabs initial={props.initial || 'general'} tabs={tabs} onSelectedTab={props.onSelectedTab} classes={props.classes} />;
}

export default withStyles(styles)(SettingsTab);
