import React from 'react';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Divider, Checkbox, Typography, MenuItem, List, ListItem, Tooltip, Box, Menu, ListItemButton, ButtonGroup } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Container, Item, EditIcon } from 'src/common/Misc';
import { DoubleArrowIcon, PlusIcon } from 'src/common/Icons';
import { Event, EventData, ContactInvite, SpreadsheetImportResult, Contact, ContactDetails, ACTION_GOLFER_CONFIRMATION, ACTION_ADD_FROM_ROSTER } from 'src/types/EventTypes';
import { inviteFrom, compareContactInvitesBy, newContact } from 'src/contact/Contact';
import { formatDateTime, formatDateUS, makeFriendlyString } from "src/util/utility";
import { inviteEmailTemplate, sendInviteEmail } from "../../../../util/email_utils";
import withStyles from '@mui/styles/withStyles';
import { appStyles, styles } from 'src/styles';
import { withUserId, WithUserId } from "../../../../auth/Auth";
import InvitesEmailDialog from "../../common/InvitesEmailDialog";
import InviteGolfersDialog from "../../common/InviteGolfersDialog";
import * as Backend from 'src/util/firebase';
import ButtonBar from "../../../../common/components/ButtonBar";
import AppButton from "../../../../common/components/AppButton";
import * as Utils from '../../../../util/utility';
import EditInviteDialog from "../../../../contact/EditInviteDialog";
import { saveContact, saveContactInvite } from "../../../Event";
import EditContactDialog from "../../../../contact/EditContactDialog";

export const ACTION_INVITE_DECLINED = 'Invite has been declined';
export const ACTION_INVITE_DELETED = 'Invite has been deleted';
export const ACTION_INVITE_CONFIRMED = 'Invite has been confirmed';
export const ACTION_INVITE_MODIFIED = 'Invite info modified';
export const ACTION_INVITE_ADDED = 'Invite info added';

type InviteItemHeaderProps = {
    selectedAll: boolean;
    sortCol?: string;
    asc?: boolean;
    selectAll: (checked: boolean) => void;
    onSortColumn: (column: string) => void;
};

interface InviteItemProps {
    invite: ContactInvite;
    clickHandler: (contactInvite: ContactInvite) => void;
    cbClickHandler: (contactInvite: ContactInvite) => void;
    selected: boolean;
}

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '150%',
        color: '#343434',
        boxShadow: theme.shadows[1],
        borderRadius: 12
    },
}))(Tooltip);

const toolTipPopperProps = {
    modifiers: [{
        name: "offset",
        options: {
            offset: [0, -10]
        },
    }]
};

const EmptyIconBox = () => <Box height={16} width={16} />;

const ContactInviteItemHeader = (props: InviteItemHeaderProps) => {
    const classes = appStyles();
    const { selectedAll, asc, sortCol, onSortColumn } = props;
    const checkBox = <Checkbox color="secondary" checked={selectedAll} onChange={(e: any, v: any) => props.selectAll(v)}
        disableRipple />;
    const rightIcon = <EditIcon invisible />;
    const sortIcon = <DoubleArrowIcon isUp={asc !== false} />;
    const [hoveredCol, setHoveredCol] = React.useState<string>('');
    return <>
        <ListItem className={classes.listItemHeaderWhite}>
            <Container wrap="nowrap">
                <Item xs={1} noWrap variant="body2">
                    <Box display="flex" flexDirection="row" alignItems="center" onMouseEnter={() => setHoveredCol('inviteDate')} onMouseLeave={() => setHoveredCol('')}>
                        {checkBox}
                        <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                            title={(sortCol === 'inviteDate' || !sortCol) && asc === false ? 'In send order' : 'Recent first then earlier'}>
                            <Box display="flex" flexDirection="row" alignItems="center"
                                onClick={() => { onSortColumn('inviteDate'); }} onMouseEnter={() => setHoveredCol('inviteDate')} onMouseLeave={() => setHoveredCol('')}>
                                Sent {sortCol === 'inviteDate' || hoveredCol === 'inviteDate' || !sortCol ? sortIcon : <EmptyIconBox />}
                            </Box>
                        </LightTooltip>
                    </Box>
                </Item>
                <Item xs={3} noWrap variant="body2">
                    <Box display="flex" flexDirection="row" alignItems="center" onMouseEnter={() => setHoveredCol('email')} onMouseLeave={() => setHoveredCol('')}>
                        <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                            title={sortCol !== 'email' || asc ? 'A-Z' : asc === false ? 'Z-A' : ''}>
                            <Box display="flex" flexDirection="row" alignItems="center"
                                onClick={() => { onSortColumn('email'); }} onMouseEnter={() => setHoveredCol('email')} onMouseLeave={() => setHoveredCol('')}>
                                To {sortCol === 'email' || hoveredCol === 'email' ? sortIcon : <EmptyIconBox />}
                            </Box>
                        </LightTooltip>
                    </Box>
                </Item>
                <Item xs={4} noWrap variant="body2" onClick={() => { onSortColumn('name'); }}>
                    <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                        title={(sortCol === 'name' || !sortCol) && asc === false ? 'Z-A' : 'A-Z'}>
                        <Box display="flex" flexDirection="row" alignItems="center"
                            onClick={() => { onSortColumn('name'); }} onMouseEnter={() => setHoveredCol('name')} onMouseLeave={() => setHoveredCol('')}>
                            Name {sortCol === 'name' || hoveredCol === 'name' ? sortIcon : <EmptyIconBox />}
                        </Box>
                    </LightTooltip>
                </Item>
                <Item xs={1} noWrap variant="body2" onClick={() => {
                    onSortColumn('status');
                }}>
                    <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                        title={sortCol !== 'status' || asc ? 'A-Z' : asc === false ? 'Z-A' : ''}>
                        <Box display="flex" flexDirection="row" alignItems="center"
                            onClick={() => {
                                onSortColumn('status');
                            }} onMouseEnter={() => setHoveredCol('status')} onMouseLeave={() => setHoveredCol('')}>
                            Status {sortCol === 'status' || hoveredCol === 'status' ? sortIcon : <EmptyIconBox />}
                        </Box>
                    </LightTooltip>
                </Item>
                <Item xs={2} noWrap variant="body2" onClick={() => {
                    onSortColumn('statusDate');
                }}>
                    <LightTooltip PopperProps={toolTipPopperProps} disableInteractive placement="top"
                        title={sortCol !== 'statusDate' || asc ? 'Earlier to recent' : asc === false ? 'Recent to earlier' : ''}>
                        <Box display="flex" flexDirection="row" alignItems="center"
                            onClick={() => {
                                onSortColumn('statusDate');
                            }} onMouseEnter={() => setHoveredCol('statusDate')}
                            onMouseLeave={() => setHoveredCol('')}>
                            Date/Time {sortCol === 'statusDate' || hoveredCol === 'statusDate' ? sortIcon :
                                <EmptyIconBox />}
                        </Box>
                    </LightTooltip>
                </Item>
                <Item xs={1} noWrap variant="body2" placeRight className={classes.colorWhite}>{rightIcon}</Item>
            </Container>
        </ListItem>
        <Divider />
        <Divider />
    </>;
};

const ContactInviteItem = withStyles(styles)((props: InviteItemProps & WithStyles<typeof styles>) => {
    const { selected, invite, classes, cbClickHandler, clickHandler } = props;
    const rightIcon = <EditIcon />;
    const decorations = classes.listItem + (selected ? (' ' + classes.selected) : '');
    const statusDecorations = invite.inviteStatus === 'declined' ? classes.redText : '';
    const checkBox = (
        <Checkbox color="secondary" checked={selected} style={{ backgroundColor: 'inherit', color: 'inherit', }}
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                cbClickHandler(invite);
            }} disableRipple />
    );
    return <>
        <ListItemButton className={decorations} onClick={() => clickHandler(invite)}>
            <Container wrap="nowrap">
                <Item xs={1} paddingRight={4}>{checkBox}{formatDateUS(invite.inviteDate)}</Item>
                <Item xs={3} paddingRight={4}>{invite.email}</Item>
                <Item xs={4} paddingRight={4}><div className={classes.flexCenteredRow21px}>{((invite.firstName + ' ') ?? '') + invite.lastName ?? ''}</div></Item>
                <Item xs={1} paddingRight={4} className={statusDecorations}>{makeFriendlyString(invite.inviteStatus, false)}</Item>
                <Item xs={2} paddingRight={4}>{!!invite.statusDate ? formatDateTime(invite.statusDate) : ''}</Item>
                <Item xs={1} placeRight className={classes.colorWhite}>{rightIcon}</Item>
            </Container>
        </ListItemButton>
        <Divider />
    </>;
});

type Props = { event: Event; eventData: EventData; } & WithStyles<typeof styles> & WithUserId;

interface State {
    importingFromRoster: boolean;
    importingFromSpreadsheet: boolean;
    addInvitesOpen: boolean;
    actionsMenuOpen: boolean;
    selectMenuOpen: boolean;
    actionResentInviteOpen: boolean;
    inviteDialogOpen: boolean;
    inviteEmailDialogOpen: boolean;
    editedInvite?: ContactInvite;
    invitedContact?: ContactDetails;
    selectedInvitesMap: Map<string, ContactInvite>;
    selectedAll: boolean;
    importResult?: SpreadsheetImportResult;
    handleInviteExport: boolean;
    sortCol?: string;
    asc?: boolean;
    inviteRecipients?: ContactDetails[];
}

class InvitesList extends React.Component<Props, State> {
    private readonly addMenuRef: React.RefObject<HTMLDivElement> = React.createRef();
    private readonly selectMenuRef: React.RefObject<HTMLButtonElement> = React.createRef();
    state: State = {
        importingFromRoster: false,
        importingFromSpreadsheet: false,
        inviteDialogOpen: false,
        inviteEmailDialogOpen: false,
        addInvitesOpen: false,
        actionsMenuOpen: false,
        actionResentInviteOpen: false,
        selectMenuOpen: false,
        selectedInvitesMap: new Map(),
        selectedAll: false,
        handleInviteExport: false
    };

    componentDidMount() {
        Backend.trackEvent('view_invites');
    }

    render() {
        const { eventData } = this.props;
        const { event, rounds } = this.getStaff();
        const { inviteRecipients, inviteEmailDialogOpen, inviteDialogOpen, editedInvite, invitedContact, selectedInvitesMap } = this.state;
        return <>
            {editedInvite && <EditInviteDialog
                open
                event={event}
                eventData={eventData}
                initialInvite={editedInvite}
                handleClose={this.handleEditInviteDialogClosed}
                handleSave={this.handleSaveInvite}
                handleConfirm={this.handleConfirmInvite}
                handleHide={this.handleHideInvite}
                handleResend={this.handleResendInvite} />}
            {inviteEmailDialogOpen && <InvitesEmailDialog
                open
                event={event}
                eventData={eventData}
                handleCancel={this.handleInviteEmailDialogClosed}
                handleSent={this.handleInviteClose}
                initialInvites={inviteRecipients?.map(r => { return { email: r.email ?? '' } as ContactInvite }) ?? Array.from(selectedInvitesMap.values())} />}
            {inviteDialogOpen && <InviteGolfersDialog
                open
                event={event}
                eventData={eventData}
                handleAddToEvent={(contact) => saveContact(event, contact, ACTION_ADD_FROM_ROSTER)}
                handleReviewInvite={this.handleReviewInvite}
                handleSendInvite={this.handleSendInvite}
                handleCancel={this.handleInviteClose} />}
            {!!invitedContact && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                actionMode={'confirm'}
                initialContact={invitedContact}
                saveToEvent={this.handleContactConfirmed}
                handleClose={this.handleCloseContactDialog}
            />}
            <this.Invites />
        </>;
    }

    private Invites = () => {
        const { classes, eventData } = this.props;
        const { roster } = eventData;
        const { invitedContacts } = this.getStaff();
        const { selectedInvitesMap, selectedAll, sortCol, asc } = this.state;
        const invites = Array.from(invitedContacts.values());
        return <div className={classes.listRootGrey}>
            <List disablePadding className={classes.listRootGrey}>
                <this.Buttons />
                <ContactInviteItemHeader selectedAll={selectedAll}
                    selectAll={this.selectAllInvites}
                    sortCol={sortCol}
                    asc={asc}
                    onSortColumn={this.handleSortGolfers} />
                {invites.sort(this.invitesComparator).map(invite =>
                    <ContactInviteItem key={invite.id}
                        invite={inviteFrom(invite, roster.get(invite.id))}
                        clickHandler={this.handleInviteRowClick}
                        cbClickHandler={this.handleGolferCbChange}
                        selected={selectedInvitesMap.has(invite.id)} />
                )}
            </List>
        </div>;
    }

    private Buttons = () => {
        const { classes } = this.props;
        const { selectedInvitesMap, selectMenuOpen } = this.state;
        const { invitedContacts } = this.getStaff();
        const golfersCount = (invitedContacts.size > 0 ? Utils.withS(invitedContacts.size, 'invite') : '') + (selectedInvitesMap.size > 0 ? ', ' + selectedInvitesMap.size + ' selected' : '');
        return <>
            <ListItem className={classes.listItem}>
                <ButtonBar style={{ width: '100%' }}>
                    <ButtonGroup ref={this.addMenuRef}>
                        <AppButton
                            color="secondary" onClick={this.handleInvitesClick}>
                            <PlusIcon sx={{ width: '.7em', height: '.7em' }} className={classes.leftButtonIcon} /> Invite
                        </AppButton>
                    </ButtonGroup>
                    <AppButton
                        color="info"
                        ref={this.selectMenuRef}
                        sx={{ paddingRight: '8px' }}
                        disabled={selectedInvitesMap.size == 0}
                        onClick={this.handleSelectClick}>
                        Select
                        <ArrowDropDownIcon className={classes.rightButtonIcon} />
                    </AppButton>
                    <Menu open={selectMenuOpen}
                        anchorEl={this.selectMenuRef.current}
                        onClose={this.handleSelectClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                        <MenuItem onClick={this.handleSelectAll}>All</MenuItem>
                        <MenuItem onClick={this.handleSelectNone}>None</MenuItem>
                    </Menu>
                    <AppButton color="info" onClick={this.handleResend} disabled={selectedInvitesMap.size == 0}>Resend</AppButton>
                    <AppButton color="info" onClick={this.handleConfirm} disabled={selectedInvitesMap.size == 0}>Confirm</AppButton>
                    <AppButton color="info" onClick={this.handleHide} disabled={selectedInvitesMap.size == 0}>Clear</AppButton>
                    <Typography noWrap color="textSecondary">
                        {golfersCount}
                    </Typography>
                </ButtonBar>
            </ListItem>
        </>;
    }

    private invitesComparator = (c1: ContactInvite, c2: ContactInvite) => {
        const { sortCol, asc } = this.state;
        return compareContactInvitesBy(c1, c2, sortCol || 'inviteDate', asc === undefined ? true : asc)
    };

    private handleResend = () => this.setState({ inviteEmailDialogOpen: true });

    private handleConfirm = () => {
        const { event, eventData } = this.props;
        const { selectedInvitesMap } = this.state;
        let invitedContact: ContactDetails;
        const invite = selectedInvitesMap.values().next().value;
        if (!!invite) {
            if (!!invite?.id && eventData.roster.has(invite?.id!)) {
                invitedContact = eventData.roster.get(invite.id!)!;
            } else {
                invitedContact = newContact(event, invite)
            }
            this.setState({ editedInvite: invite, invitedContact: invitedContact });
        }
    }

    private handleHide = async () => {
        const { event } = this.props;
        const { selectedInvitesMap } = this.state;
        const contactInvites = Array.from(selectedInvitesMap.values());
        for (const invite of contactInvites) {
            await saveContactInvite(event, { ...invite, hidden: true }, ACTION_INVITE_DELETED);
        }
        this.setState({ selectedInvitesMap: new Map() });
    }

    private handleSelectAll = () => this.selectAllInvites(true);
    private handleSelectNone = () => this.selectAllInvites(false);

    private handleSelectClose = () => this.setState({ selectMenuOpen: false });
    private handleSelectClick = () => this.setState({ selectMenuOpen: !this.state.selectMenuOpen });
    private handleInvitesClick = () => this.setState({ inviteDialogOpen: !this.state.inviteDialogOpen });
    private handleInviteEmailDialogClosed = () => this.setState({ inviteDialogOpen: true, inviteEmailDialogOpen: false });
    private handleEditInviteDialogClosed = () => this.setState({ editedInvite: undefined });
    private handleInviteClose = () => this.setState({ inviteDialogOpen: false, inviteRecipients: [], inviteEmailDialogOpen: false });
    private handleSaveInvite = async (contactInvite: ContactInvite, initialInvite?: ContactInvite) => {
        const { event } = this.props;
        await saveContactInvite(event, contactInvite, initialInvite?.id ? ACTION_INVITE_MODIFIED : ACTION_INVITE_ADDED);
        this.setState({ editedInvite: undefined });
    }

    private handleReviewInvite = async (recipients: ContactDetails[]) => {
        this.setState({ inviteEmailDialogOpen: true, inviteRecipients: recipients })
    };

    private handleSendInvite = async (recipients: ContactDetails[]) => {
        const { event, eventData } = this.props;
        const email = {
            subject: `Tournament invitation!`,
            replyTo: eventData.adminEmail ?? '',
            recipients: recipients,
            text: inviteEmailTemplate(event)
        }
        await sendInviteEmail(email, event, eventData);
        this.setState({ inviteDialogOpen: false, inviteEmailDialogOpen: false })
    };

    private handleContactConfirmed = async (contactDetail: Contact, notificationLess: boolean, initialContact?: Contact) => {
        const { event } = this.props;
        let { editedInvite, selectedInvitesMap } = this.state;
        const savedInvite = !editedInvite ? undefined : { ...editedInvite!, inviteStatus: "confirmed", statusDate: Date.now() } as ContactInvite;
        if (contactDetail) {
            await saveContact(event, contactDetail, ACTION_GOLFER_CONFIRMATION, notificationLess, savedInvite);
        }
        if (selectedInvitesMap.has(contactDetail.id)) {
            selectedInvitesMap.delete(contactDetail.id)
            if (selectedInvitesMap.size > 0) {
                this.handleConfirmInvite(selectedInvitesMap.values().next().value)
                return
            }
        }
        this.setState({ editedInvite: undefined, invitedContact: undefined });
    }

    private handleConfirmInvite = (contactInvite: ContactInvite) => {
        const { event, eventData } = this.props;
        let invitedContact: ContactDetails;
        if (!!contactInvite.id && eventData.roster.has(contactInvite.id!)) {
            invitedContact = eventData.roster.get(contactInvite.id!)!;
        } else {
            invitedContact = newContact(event, contactInvite)
        }
        this.setState({ editedInvite: contactInvite, invitedContact: invitedContact });
    }

    private handleHideInvite = async (contactInvite: ContactInvite) => {
        const { event } = this.props;
        await saveContactInvite(event, { ...contactInvite, hidden: true }, ACTION_INVITE_DELETED);
        this.setState({ editedInvite: undefined });
    }

    private handleResendInvite = async (contactInvite: ContactInvite) => {
        const selectedInvitesMap = new Map([[contactInvite.id, contactInvite]]);
        this.setState({ inviteEmailDialogOpen: true, selectedInvitesMap });
    }

    private handleCloseContactDialog = () => this.setState({ editedInvite: undefined, invitedContact: undefined });

    private handleInviteRowClick = (invite?: ContactInvite) => invite && this.setState({ editedInvite: { ...invite } });

    private handleSortGolfers = (column: string) => {
        let { sortCol, asc } = this.state;
        if (sortCol === column || (sortCol === undefined && column === 'inviteDate')) {
            asc = asc === undefined ? false : !asc;
        } else {
            sortCol = column;
            asc = true;
        }
        this.setState({ sortCol, asc });
    }

    private handleGolferCbChange = (invite: ContactInvite) => {
        const { invitedContacts } = this.props.eventData;
        let { selectedAll, selectedInvitesMap } = this.state;
        if (selectedInvitesMap.has(invite.id)) {
            selectedInvitesMap.delete(invite.id);
            if (selectedInvitesMap.size === 0) {
                selectedAll = false;
            }
        } else {
            selectedInvitesMap.set(invite.id, invite);
            if (selectedInvitesMap.size === invitedContacts.size) {
                selectedAll = true;
            }
        }
        this.setState({ selectedInvitesMap: selectedInvitesMap, selectedAll });
    }

    private selectAllInvites = (selectedAll: boolean) => {
        const selectedInvitesMap = new Map<string, ContactInvite>();
        if (selectedAll) {
            const { invitedContacts, roster } = this.getStaff();
            invitedContacts.forEach(invite => selectedInvitesMap.set(invite.id, inviteFrom(invite, roster.get(invite.id))));
        }
        this.setState({ selectedInvitesMap: selectedInvitesMap, selectedAll, selectMenuOpen: false });
    }

    private getStaff() {
        const { event } = this.props;
        const { rounds, invitedContacts, roster } = this.props.eventData;
        return { event, rounds, invitedContacts, roster };
    }
}

export default withStyles(styles)(withUserId(InvitesList));
