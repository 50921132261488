import * as React from 'react';
import { List, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ButtonBar from '../common/components/ButtonBar';
import AppButton from '../common/components/AppButton';
import { styles } from '../styles';
import * as Utils from '../util/utility';

type State = {
    randStr?: string;
};

const Buttons = (props: {}) => {
    return (
        <ButtonBar>
            <AppButton color="inherit" >Inherit</AppButton>
            <AppButton color="primary">Primary</AppButton>
            <AppButton color="secondary" >Secondary</AppButton>
            <AppButton color="info" >Info</AppButton>
            <AppButton color="error" >Error</AppButton>
            <AppButton color="success" >Success</AppButton>
            <AppButton color="warning" >Warning</AppButton>
        </ButtonBar>
    );
};

class TestList extends React.Component<WithStyles<typeof styles>, State> {
    state: State = {};

    render() {
        const { classes } = this.props;
        const { randStr } = this.state;
        return (
            <React.Fragment>
                LIST 1
                <List disablePadding>
                    {Utils.range(1, 4).map(i => <Buttons key={i} />)}
                </List>
                <div className={classes.root}>
                    LIST 2
                    <List disablePadding className={classes.listRootGreyRound}>
                        {Utils.range(1, 4).map(i => <Buttons key={i} />)}
                    </List>
                </div>
                <div className={classes.root}>
                    <List disablePadding className={classes.listRootGreyRound}>
                        <AppButton color="secondary" onClick={() => this.setState({ randStr: Utils.randomString(5) })}>Test Random String</AppButton>
                        &nbsp;&nbsp;&nbsp;{randStr}
                    </List>
                </div>
                <div className={classes.root}>
                    <Typography>Typography</Typography>
                    <Typography color="aqua">Typography</Typography>
                </div>
            </React.Fragment >
        );
    }
}

export default withStyles(styles)(TestList);
