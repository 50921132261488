import * as React from 'react';
import { List, ListItem, Hidden, Typography } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import PersonIcon from '@mui/icons-material/Person';
import * as Backend from '../util/firebase';
import { Event, Contact, GolferGroup, Team, teeTimeName, Round, EventBase } from '../types/EventTypes';
import { fullName, golferTeamFullNames, getSameNameGolfersIds } from '../contact/Contact';
import { FirebaseDataComponent } from '../common/WithData';
import { getSchedule, ScheduleItem } from '../event/TeeTimes';
import { Container, Item, ItemS, VertDivider, HorzDivider, Label } from '../common/Misc';
import { styles } from '../styles';
import AppButton from 'src/common/components/AppButton';
import ButtonBar from 'src/common/components/ButtonBar';
import { AppColors } from 'src/main/Theme';
import { fixTeamsHandicapAndGender } from 'src/scoring/handicap';

type User = { name: string, last?: boolean };
type Teams = Array<User>;

interface ItemProps {
    tee: string;
    teams: Teams;
    teamSize: number;
    groupSize: number;
}

function getScheduleRows(eventOrRound: EventBase, groups: Array<GolferGroup>, golfers: Map<string, Contact>, teams: Map<string, Team>) {
    let scheduleRows: ScheduleRows = [];
    const schedule = getSchedule(eventOrRound, groups, golfers, teams);
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    let groupSize = 0;
    if (schedule && eventOrRound.teamSize === 1) {
        schedule.forEach(s => {
            if (s.golfers) {
                if (groupSize < s.golfers.length) {
                    groupSize = s.golfers.length;
                }
                const rowTeams: Teams = [];
                for (let i = 0; i < s.golfers.length; i++) {
                    if (s.golfers[i]) {
                        const golfer: Contact = s.golfers[i];
                        rowTeams.push({ name: fullName(golfer).concat(sameNameGolfersIdsSet.has(golfer.id) && golfer.homeCourseOrCity ? ` (${golfer.homeCourseOrCity})` : '') });
                    }
                }
                if (rowTeams.length > 0) {
                    scheduleRows.push({ time: teeTimeName(s.time), teams: rowTeams });
                }
            }
        });
    } else if (schedule && eventOrRound.teamSize > 1) {
        groupSize = eventOrRound.teeTime.golfersPerGroup;
        schedule.forEach(s => {
            if (s.teams) {
                const rowTeams: Teams = [];
                for (let i = 0; i < s.teams.length; i++) {
                    const contacts = s.teams[i] ? golferTeamFullNames(s.teams[i], golfers, sameNameGolfersIdsSet) : [];
                    const contactsNum = contacts.length;
                    while (contacts.length < eventOrRound.teamSize) {
                        contacts.push('');
                    }
                    contacts.forEach((contact, idx) => rowTeams.push({ name: contact, last: idx === contacts.length - 1 && contactsNum > 0 && i < s.teams.length - 1 }));
                }
                if (rowTeams.length > 0) {
                    scheduleRows.push({ time: teeTimeName(s.time), teams: rowTeams });
                }
            }
        });
    }
    return { scheduleRows, groupSize };
}

const UserElem = withStyles(styles)((props: { user: User, height: number, xs?: GridSize, sm?: GridSize, md?: GridSize, lg?: GridSize, className?: string } & WithStyles<typeof styles>) => {
    const { classes, user, height, xs, sm, md, lg, className } = props;
    return (
        <ItemS xs={xs} sm={sm} md={md} lg={lg} neg height={height}>
            <PersonIcon className={classes.textIcon + (user.name ? '' : ' ' + classes.invisible)} />
            <Label backgroundColor="inherit" className={className} paddingLeft={0}>
                {user.name}
            </Label>
        </ItemS>
    );
});

const GroupElem = withStyles(styles)((props: { user: User, height: number, teamSize: number, groupSize: number } & WithStyles<typeof styles>) => {
    const { classes, user, height, teamSize, groupSize } = props;
    if (teamSize === 1) {
        if (groupSize === 1) {
            return <UserElem user={user} height={height} xs={12} />;
        } else if (groupSize === 2) {
            return <UserElem user={user} height={height} xs={12} sm={6} />;
        } else if (groupSize === 3) {
            return <UserElem user={user} height={height} xs={12} sm={6} md={4} />;
        } else {
            return <UserElem user={user} height={height} xs={12} sm={6} md={4} lg={3} />;
        }
    } else if (teamSize === 2 && groupSize === 4) {
        return (
            <React.Fragment>
                <Hidden lgDown>
                    <React.Fragment>
                        <UserElem user={user} height={height} xs={3} className={classes.paddingLeftRight} />
                        {user.last && <VertDivider height={height - 10} padding={5} />}
                    </React.Fragment>
                </Hidden>
                <Hidden lgUp>
                    <React.Fragment>
                        <UserElem user={user} height={height} xs={12} sm={6} />
                        {user.last && <HorzDivider />}
                    </React.Fragment>
                </Hidden>
            </React.Fragment>
        );
    } else if (teamSize === 2) {
        return (
            <React.Fragment>
                <UserElem user={user} height={height} xs={12} sm={6} />
                {user.last && <HorzDivider />}
            </React.Fragment>
        );
    } else if (teamSize === 3) {
        return (
            <React.Fragment>
                <UserElem user={user} height={height} xs={12} sm={6} md={4} />
                {user.last && <HorzDivider />}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            <UserElem user={user} height={height} xs={12} sm={6} md={4} lg={3} />
            {user.last && <HorzDivider />}
        </React.Fragment>
    );
});

const RowElem = withStyles(styles)((props: { teams: Teams, height: number, teamSize: number, groupSize: number } & WithStyles<typeof styles>) => {
    const { teams, height, teamSize, groupSize } = props;
    return (
        <React.Fragment>
            <Container>
                {teams.map((user, idx) => <GroupElem key={idx} user={user} height={height} teamSize={teamSize} groupSize={groupSize} />)}
            </Container>
        </React.Fragment>
    );
});

const ScheduleRow = withStyles(styles)((props: ItemProps & WithStyles<typeof styles>) => {
    const { classes, teams, teamSize, groupSize } = props;
    const height = 42;
    return (
        <ListItem className={classes.listItemZebra + ' ' + classes.padding0} dense>
            <Container>
                <Item xs={3} sm={2} height={height} paddingLeft="1em">{props.tee}</Item>
                <ItemS xs={9} sm={10}>
                    <RowElem teams={teams} height={height} teamSize={teamSize} groupSize={groupSize} />
                </ItemS>
            </Container>
        </ListItem>
    );
});

type ScheduleRows = Array<{ time: string, teams: Teams }>;

const ScheduleHeader = withStyles(styles)((props: { tee: string } & WithStyles<typeof styles>) => {
    const { classes } = props;
    const height = 42;
    return (
        <ListItem className={classes.listItemHeader + ' ' + classes.padding0}>
            <Container>
                <Item xs={3} sm={2} height={height} paddingLeft="1em">{props.tee}</Item>
                <Item xs={9} sm={10} height={height} paddingLeft="1em">Golfers</Item>
            </Container>
        </ListItem>
    );
});

interface State {
    schedule?: ScheduleItem[];
    groupsMap: Map<string, Array<GolferGroup>>;
    golfersMap: Map<string, Map<string, Contact>>;
    teamsMap: Map<string, Map<string, Team>>;
    loadedTeams: number;
    loadedGroups: number;
    loadedGolfers: number;
}

interface ScheduleProperties {
    event: Event;
    rounds: Array<Round>;
    selectedRound?: Round;
    setSelectedRound: (selectedRound?: Round) => void;
}

type Props = ScheduleProperties & WithStyles<typeof styles>;

class Schedule extends React.Component<Props, State> {
    state: State = {
        groupsMap: new Map(),
        golfersMap: new Map(),
        teamsMap: new Map(),
        loadedTeams: 0,
        loadedGroups: 0,
        loadedGolfers: 0,
    };

    private onGolfers = (baseEvent: EventBase, golfers: Map<string, Contact>) => {
        const { golfersMap, loadedGolfers } = this.state;
        golfersMap.set(baseEvent.id, golfers);
        this.setState({ golfersMap, loadedGolfers: loadedGolfers + 1 });
    }

    private onGroups = (baseEvent: EventBase, groups: Array<GolferGroup>) => {
        const { loadedGroups, groupsMap } = this.state;
        groupsMap.set(baseEvent.id, groups);
        this.setState({ groupsMap, loadedGroups: loadedGroups + 1 });
    }

    private onTeams = (baseEvent: EventBase, allTeams: Map<string, Team>) => {
        const { loadedTeams, golfersMap, teamsMap } = this.state;
        const golfers = golfersMap.get(baseEvent.id) ?? new Map<string, Contact>();
        const teams = new Map(
            Array.from(allTeams.entries())
                .filter(entry => entry[1].contactIds.some(golferId => golfers.has(golferId)))
        );
        fixTeamsHandicapAndGender(teams, golfers);
        teamsMap.set(baseEvent.id, teams);
        this.setState({ teamsMap, loadedTeams: loadedTeams + 1 });
    }

    private selectedRound() {
        const { event, rounds } = this.props;
        let { selectedRound } = this.props;
        if (event.type === 'multiday' && !selectedRound && rounds.length > 0) {
            selectedRound = rounds[0];
        }
        return selectedRound;
    }

    private getStaff() {
        const { event } = this.props;
        const { golfersMap, teamsMap, groupsMap, loadedTeams, loadedGroups, loadedGolfers } = this.state;
        const eventOrRound = this.selectedRound() ?? event;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        return { eventOrRound, golfers, teams, groups, loadedTeams, loadedGroups, loadedGolfers };
    }

    render() {
        const { event, rounds, setSelectedRound, classes } = this.props;
        const { groups, golfers, teams, loadedTeams, loadedGroups, loadedGolfers } = this.getStaff();
        const selectedRound = this.selectedRound();
        const eventOrRound = selectedRound ?? event;
        const isRegular = eventOrRound.teeTime.mode === 'regular';
        const { scheduleRows, groupSize } = getScheduleRows(eventOrRound, groups, golfers, teams);
        const multiday = event.type === 'multiday';
        const events = multiday ? rounds : [event];
        return <>
            {multiday && <ButtonBar margin>
                {rounds.map(round => <AppButton
                    key={round.roundOrder}
                    className={classes.eventRoundButton}
                    color={round.roundOrder === selectedRound?.roundOrder ? "primary" : "info"}
                    sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                    onClick={() => setSelectedRound(round)} >
                    Round {round.roundOrder}
                </AppButton>)}
            </ButtonBar>}
            {scheduleRows.length > 0 && <List disablePadding>
                <ScheduleHeader tee={isRegular ? 'Time' : 'Hole'} />
                {scheduleRows.map(row => <ScheduleRow
                    key={row.time}
                    tee={teeTimeName(row.time)}
                    teams={row.teams}
                    teamSize={eventOrRound.teamSize}
                    groupSize={groupSize}
                />)}
            </List>}
            {!scheduleRows.length && loadedTeams > 0 && loadedGroups > 0 && loadedGolfers > 0 &&
                <Typography className={classes.portalInfoNotSet}>
                    The event schedule will be visible here, once it is set by the tournament organizer.
                </Typography>}
            {events.map(round => <FirebaseDataComponent<Contact>
                key={round.id}
                name="golfers"
                queryPath={round.id}
                query={Backend.eventGolfersQuery(round.id)}
                onMap={golfers => this.onGolfers(round, golfers)} />)}
            {events.map(round => <FirebaseDataComponent<Team>
                key={round.id}
                name="teams"
                queryPath={round.id}
                query={Backend.eventTeamsQuery(round.id)}
                onMap={teams => this.onTeams(round, teams)} />)}
            {events.map(round => <FirebaseDataComponent<GolferGroup>
                key={round.id}
                name="groups"
                queryPath={round.id}
                query={Backend.golferGroupDb(round.id)}
                onData={groups => this.onGroups(round, groups)} />)}
        </>;
    }
}

export default withStyles(styles)(Schedule);
