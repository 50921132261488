import {
    EventGender, Team, Contact, HolesType, Tee, Score, ReportedScore, ScoringMode, MAX_HOLES, HOLES_9, HOLES_9_9, FlightsNamingMode, HandicapSystem, TeeTime, isRegular,
    EventBase, Event, Round, isNetMode, isDistanceScoring, isSkinsScoring, getHolesRange, isMainScoring, isSideGameScoring, isGrossMode, getTotalHoles,
    ScoringFormatTeams, ContactScoringState, BaseScoringState, ScoringFormatSkins, GolferGroup, CalculatedScores, teamsOfFlight, contactsOfFlight, getTee, isTeamFormat,
    teamsOf, golfersOfCompetition, ScoringData, Competition, CalculatedFlightScores, ScoringFormatIndividual, HolesRange, Par, getFlightOfContact,
    isStablefordScoringOrMode, compareWinnersIfPresent, getFlightNumbersForIds, BaseScoringInfo,
    ContactRoundsScores,
} from '../types/EventTypes';
import { golfersOfTeam, golferShortTeamNameArray, fullName, getSameNameGolfersIds } from '../contact/Contact';
import { getGolferRangeHandicap, getHoleHandicapByHC, hardestTee, getPlayingHandicap, getHandicapsAllowance } from './handicap';
import * as Utils from '../util/utility';
import { getTeeTime, getFlightName } from '../event/TeeTimes';

export function getFlights(competition: Competition) {
    return competition.flights ? Utils.range(1, competition.flights + 1) : [0];
}

export function scoringNameShort(scoring: ScoringData) {
    return Utils.makeFriendlyString(scoring.format, true);
}

export function equalScorings(scoringA: ScoringData, scoringB: ScoringData) {
    if (scoringA.format !== scoringB.format) {
        return false;
    }
    if (isDistanceScoring(scoringA)) {
        return Utils.equalArrays<number>(scoringA.holes, scoringB.holes);
    } else {
        return scoringA.mode === scoringB.mode && (scoringA.mode === 'gross' || Utils.equalArrays<number>(scoringA.handicaps, scoringB.handicaps));
    }
}

export function scoringName(competition: Competition, eventGender?: EventGender, competitionGender?: EventGender, skinsMixed?: boolean, flight?: number, flightsNaming?: FlightsNamingMode, competitionMode?: ScoringMode) {
    const { scoring } = competition;
    const { format } = scoring;
    const mode = competitionMode || scoring.mode;
    let name;
    if (competition.name) {
        name = competition.name;
        if (competition.fromBothModes) {
            name += `, ${mode}`;
        }
        return Utils.makeFriendlyString(name, true);
    }
    if (isDistanceScoring(scoring)) {
        name = `${format}`;
    } else if (isSkinsScoring(scoring)) {
        if (skinsMixed) {
            name = `${format}, ${mode}`;
        } else {
            name = `skins, ${mode}`;
        }
    } else {
        const formatName = competition.scoring.format === ScoringFormatIndividual.modified_stableford ?
            ScoringFormatIndividual.stableford : competition.scoring.format;
        name = `${formatName}, ${mode}`;
    }
    if (!eventGender || eventGender === 'both') {
        if (competitionGender === 'men') {
            name += ' - men';
        } else if ((!eventGender || eventGender === 'both') && competitionGender === 'women') {
            name += ' - women';
        }
    }
    if (flight) {
        const flightName = getFlightName(flight, flightsNaming);
        name += (', ' + flightName);
    }
    return Utils.makeFriendlyString(name, true);
}

export function getTotalScoreFunc(competition?: Competition | null) {
    return (g: BaseScoringInfo) =>
        !competition ? g.total :
            isStablefordScoringOrMode(competition.scoring) ?
                -(isNetMode(competition.scoring.mode) ? g.stablefordNet : g.stableford) :
                (isNetMode(competition.scoring.mode) ? g.net : g.total);
}

export function getRelScoreFunc(competition?: Competition | null) {
    return (g: BaseScoringInfo) =>
        !competition ? g.relativeTotal :
            isStablefordScoringOrMode(competition.scoring) ?
                -(isNetMode(competition.scoring.mode) ? g.stablefordNet : g.stableford) :
                (isNetMode(competition.scoring.mode) ? g.relativeNet : g.relativeTotal);
}

function isContactAndNotATeam(obj: any): obj is Contact {
    return 'lastName' in obj;
}

export function scoreCompare(competition: Competition) {
    const getScore = getTotalScoreFunc(competition);
    return (c1: ContactScoringState, c2: ContactScoringState) => {
        const winnersComparingResult = compareWinnersIfPresent(competition, c1.contactId, c2.contactId);
        if (winnersComparingResult !== 0) {
            return winnersComparingResult;
        }
        const score1 = c1.contactInfo.disqualified ? 100000 : c1.contactInfo.withdrawn ? 10000 : c1.holes === c1.totalHoles ? getScore(c1) : c1.holes > 0 ? getScore(c1) + 500 : 1000;
        const score2 = c2.contactInfo.disqualified ? 100000 : c2.contactInfo.withdrawn ? 10000 : c2.holes === c2.totalHoles ? getScore(c2) : c2.holes > 0 ? getScore(c2) + 500 : 1000;
        const scoreDiff = score1 - score2;
        if (scoreDiff !== 0) {
            return scoreDiff;
        }
        if (competition.scoring.mode !== 'gross' && c1.total && c2.total) {
            const totalDiff = c1.total - c2.total;
            if (totalDiff !== 0) {
                return totalDiff;
            }
        }
        const thruDiff = c1.holes - c2.holes;
        if (thruDiff !== 0) {
            return -thruDiff;
        }
        let teeTimeDifference = 0;
        if (c1.teeTime && c2.teeTime) {
            if (isRegular(c1.teeTime) && isRegular(c2.teeTime)) {
                teeTimeDifference = c1.teeTime - c2.teeTime;
            }
            if (!isRegular(c1.teeTime) && !isRegular(c2.teeTime)) {
                teeTimeDifference = c1.teeTime.localeCompare(c2.teeTime);
            }
        }
        if (teeTimeDifference === 0) {
            const firstPlayer: Team | Contact = c1.player;
            const secondPlayer: Team | Contact = c2.player;
            if (isContactAndNotATeam(firstPlayer) && isContactAndNotATeam(secondPlayer)) {
                const firstStr: string = firstPlayer.firstName ? firstPlayer.firstName + firstPlayer.lastName : firstPlayer.lastName;
                const secondStr: string = secondPlayer.firstName ? secondPlayer.firstName + secondPlayer.lastName : secondPlayer.lastName;
                return firstStr.localeCompare(secondStr);
            }
        } else {
            return teeTimeDifference;
        }
        return c1?.names?.length && c2?.names?.length ? c1.names[0].localeCompare(c2.names[0]) : 1;
    };
}

export function scoreCompareRounds(competition: Competition) {
    const getScore = getTotalScoreFunc(competition);
    return (c1: ContactRoundsScores, c2: ContactRoundsScores) => {
        const winnersComparingResult = compareWinnersIfPresent(competition, c1.contactId, c2.contactId);
        if (winnersComparingResult !== 0) {
            return winnersComparingResult;
        }
        const score1 = c1.disqualified ? 100000 : c1.withdrawn ? 10000 : c1.holes === c1.totalHoles ? getScore(c1) : c1.holes > 0 ? getScore(c1) + 500 : 1000;
        const score2 = c2.disqualified ? 100000 : c2.withdrawn ? 10000 : c2.holes === c2.totalHoles ? getScore(c2) : c2.holes > 0 ? getScore(c2) + 500 : 1000;
        const scoreDiff = score1 - score2;
        if (scoreDiff !== 0) {
            return scoreDiff;
        }
        if (competition.scoring.mode !== 'gross' && c1.total && c2.total) {
            const totalDiff = c1.total - c2.total;
            if (totalDiff !== 0) {
                return totalDiff;
            }
        }
        const thruDiff = c1.holes - c2.holes;
        if (thruDiff !== 0) {
            return -thruDiff;
        }
        const firstPlayer: Team | Contact = c1.player;
        const secondPlayer: Team | Contact = c2.player;
        if (isContactAndNotATeam(firstPlayer) && isContactAndNotATeam(secondPlayer)) {
            const firstStr: string = firstPlayer.firstName ? firstPlayer.firstName + firstPlayer.lastName : firstPlayer.lastName;
            const secondStr: string = secondPlayer.firstName ? secondPlayer.firstName + secondPlayer.lastName : secondPlayer.lastName;
            return firstStr.localeCompare(secondStr);
        }
        return c1?.names?.length && c2?.names?.length ? c1.names[0].localeCompare(c2.names[0]) : 1;
    };
}

function calcScoresPos(scores: Array<BaseScoringInfo>, competition?: Competition | null): void {
    const getScore = getTotalScoreFunc(competition);
    let pos = -1;
    for (let i = 0; i < scores.length; ++i) {
        const scoringState1 = scores[i];
        const scoringState2 = i < scores.length - 1 ? scores[i + 1] : null;
        const equals = !!scoringState2 && (getScore(scoringState1) === getScore(scoringState2));
        const currentIsWinner = competition?.winners?.find(winnerInfo =>
            winnerInfo.contactId === scoringState1.contactId && competition.scoring.mode === winnerInfo.mode);
        if ((equals || pos !== -1) && !currentIsWinner) {
            if (pos === -1) {
                pos = i + 1;
            }
            scoringState1.pos = `T${pos}`;
        } else {
            scoringState1.pos = `${i + 1}`;
        }
        if (!equals || currentIsWinner) {
            pos = -1;
        }
    }
}

export function totalGross(score?: Score, reportedScore?: ReportedScore, holesType?: HolesType) {
    let res = 0;
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        res += (score?.gross && score.gross[hole] > 0) ? score.gross[hole] :
            (reportedScore?.strokes && reportedScore.strokes[hole] > 0) ? reportedScore.strokes[hole] : 0;
    }
    return res;
}

export function relativeGrossTotal(score?: Score, reportedScore?: ReportedScore, holesType?: HolesType, tee?: Tee | null) {
    const pars = tee && tee.par;
    let res = 0;
    let par = 0;
    let pts: number | undefined = 0;
    const holesRange = getHolesRange(holesType);
    if (score || reportedScore) {
        for (let hole = holesRange.first; hole < holesRange.last; hole++) {
            par = (pars && pars[holesType === HOLES_9_9 && hole > 8 ? hole - 9 : hole]) || 0;
            pts = (score?.gross && score.gross[hole] > 0) ? score.gross[hole] : (reportedScore?.strokes && reportedScore.strokes[hole] > 0) ? reportedScore.strokes[hole] : undefined;
            res += pts !== undefined ? pts - par : 0;
        }
    }
    return res;
}

export function playedHoles(holesRange: HolesRange, score?: Score, reportedScore?: ReportedScore, noticePickUps?: boolean) {
    let res = 0;
    for (let holeIndex = holesRange.first; holeIndex < holesRange.last; holeIndex++) {
        res += score && ((noticePickUps && score.pickUps && score.pickUps[holeIndex]) || (score.gross && score.gross[holeIndex] > 0)) ? 1
            : reportedScore && ((noticePickUps && reportedScore.pickUps && reportedScore.pickUps[holeIndex]) || (reportedScore.strokes && reportedScore.strokes[holeIndex] > 0)) ? 1 : 0;
    }
    return res;
}

function getDoubleBogey(holePar: number, holeHandicap?: number): number {
    return holePar + 2 + (holeHandicap ?? 0);
}

export function scoreNet(handicapSystem: HandicapSystem, hole: number, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, holesType?: HolesType, tee?: Tee | null) {
    const scoreGross: boolean = Boolean(score?.gross && score.gross[hole] > 0);
    const reportedScoreStrokes: boolean = Boolean(reportedScore?.strokes && reportedScore.strokes[hole] > 0);
    const scorePickUps: boolean = Boolean(score?.pickUps && score.pickUps[hole]);
    const reportedScorePickUps: boolean = Boolean(reportedScore?.pickUps && reportedScore.pickUps[hole]);
    if (!scoreGross && !scorePickUps && !reportedScoreStrokes && !reportedScorePickUps) {
        return undefined;
    }
    const hc = getHoleHandicapByHC(handicapSystem, hole, holesType, tee, playingHandicap);
    if (!scoreGross && !scorePickUps && (reportedScoreStrokes || reportedScorePickUps)) {
        if (reportedScorePickUps && tee) {
            return getDoubleBogey(tee.par[hole % tee.par.length], hc);
        } else if (reportedScoreStrokes) {
            return reportedScore!.strokes[hole] - hc;
        }
    } else {
        if (scorePickUps && tee) {
            return getDoubleBogey(tee.par[hole % tee.par.length], hc);
        } else if (scoreGross) {
            return score!.gross[hole] - hc;
        }
    }
    return undefined;
}

export function totalNet(handicapSystem: HandicapSystem, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, holesType?: HolesType, tee?: Tee | null) {
    let total = 0;
    if ((!score || !score.gross) && (!reportedScore || !reportedScore.strokes)) {
        return total;
    }
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const netScore = scoreNet(handicapSystem, hole, playingHandicap, score, reportedScore, holesType, tee);
        if (netScore) {
            total += netScore;
        }
    }
    return total;
}

export function getNets(handicapSystem: HandicapSystem, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, holesType?: HolesType, tee?: Tee | null) {
    if ((!score || !score.gross) && (!reportedScore || !reportedScore.strokes)) {
        return undefined;
    }
    const nets: Array<number | undefined> = [];
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        nets[hole] = scoreNet(handicapSystem, hole, playingHandicap, score, reportedScore, holesType, tee);
    }
    return nets;
}

export function totalNetBase(netScores: Array<number | undefined>, holesType?: HolesType) {
    let total = 0;
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const netScore = netScores[hole];
        if (netScore) {
            total += netScore;
        }
    }
    return total;
}

export function relativeNetTotal(handicapSystem: HandicapSystem, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, holesType?: HolesType, tee?: Tee | null) {
    let total = 0;
    if ((!score || !score.gross) && (!reportedScore || !reportedScore.strokes)) {
        return total;
    }
    const pars = tee && tee.par;
    const holesRange = getHolesRange(holesType);
    let par = 0;
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const netScore = scoreNet(handicapSystem, hole, playingHandicap, score, reportedScore, holesType, tee);
        if (netScore || netScore === 0) {
            par = (pars && pars[holesType === HOLES_9_9 && hole > 8 ? hole - 9 : hole]) || 0;
            total += netScore - par;
        }
    }
    return total;
}

export function relativeNetTotalBase(netScores: Array<number | undefined>, holesType?: HolesType, tee?: Tee | null) {
    let total = 0;
    const pars = tee && tee.par;
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const netScore = netScores[hole];
        if (netScore || netScore === 0) {
            total += netScore - ((pars && pars[hole]) || 0);
        }
    }
    return total;
}

function relativeStablefordPt(strokes: number, par: number, mstablefordPoints?: Readonly<Array<number>>) {
    if (mstablefordPoints && mstablefordPoints.length > 0) {
        const maxOffsetFromPar = mstablefordPoints.length === 8 ? 3 : 2;
        let strokesOverPar = par + maxOffsetFromPar - strokes;
        if (strokesOverPar < 0) {
            strokesOverPar = 0;
        } else if (strokesOverPar >= mstablefordPoints.length) {
            strokesOverPar = mstablefordPoints.length - 1;
        }
        return mstablefordPoints[strokesOverPar];
    }
    const points = 2 + par - strokes;
    return points < 0 ? 0 : points > 6 ? 6 : points;
}

export function relativeStablefordPoints(hole: number, score?: Score, reportedScore?: ReportedScore, mstablefordPoints?: Readonly<Array<number>>, holesType?: HolesType, tee?: Tee | null) {
    if ((!score || !score.gross || !score.gross[hole]) && (!reportedScore || !reportedScore.strokes || !reportedScore.strokes[hole])) {
        return undefined;
    }
    const pars = tee && tee.par;
    const courseHoleCount = holesType === HOLES_9 || holesType === HOLES_9_9 ? 9 : 18;
    const pts = (!score || !score.gross[hole]) && reportedScore && reportedScore.strokes[hole] ? reportedScore.strokes[hole] : score!.gross[hole];
    return relativeStablefordPt(pts, (pars && pars[hole % courseHoleCount]) || 0, mstablefordPoints);
}

export function relativeStablefordNetPoints(handicapSystem: HandicapSystem, hole: number, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, mstablefordPoints?: Readonly<Array<number>>, holesType?: HolesType, tee?: Tee | null) {
    if ((!score || !score.gross || !score.gross[hole]) && (!reportedScore || !reportedScore.strokes || !reportedScore.strokes[hole])) {
        return undefined;
    }
    const netScore = scoreNet(handicapSystem, hole, playingHandicap, score, reportedScore, holesType, tee);
    const pars = tee && tee.par;
    const courseHoleCount = holesType === HOLES_9 || holesType === HOLES_9_9 ? 9 : 18;
    if (netScore || netScore === 0) {
        return relativeStablefordPt(netScore, (pars && pars[hole % courseHoleCount]) || 0, mstablefordPoints);
    }
    return 0;
}

export function relativeTotalStablefordPoints(score?: Score, reportedScore?: ReportedScore, mstablefordPoints?: Readonly<Array<number>>, holesType?: HolesType, tee?: Tee | null) {
    let total = 0;
    if ((!score || !score.gross) && (!reportedScore || !reportedScore.strokes)) {
        return total;
    }
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const pointsScore = relativeStablefordPoints(hole, score, reportedScore, mstablefordPoints, holesType, tee);
        if (pointsScore) {
            total += pointsScore;
        }
    }
    return total;
}

export function relativeTotalStablefordNetPoints(handicapSystem: HandicapSystem, playingHandicap: number, score?: Score, reportedScore?: ReportedScore, mstablefordPoints?: Readonly<Array<number>>, holesType?: HolesType, tee?: Tee | null) {
    let total = 0;
    if ((!score || !score.gross) && (!reportedScore || !reportedScore.strokes)) {
        return total;
    }
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const netScore = relativeStablefordNetPoints(handicapSystem, hole, playingHandicap, score, reportedScore, mstablefordPoints, holesType, tee);
        if (netScore) {
            total += netScore;
        }
    }
    return total;
}

export function formatRelativeScore(score?: number) {
    if (!score) {
        return 'E';
    } else {
        return (score > 0) ? `+${score}` : score;
    }
}

export function formatTotalScore(roundsNum: number, total?: number) {
    return total ? total : roundsNum === 1 ? 0 : '-';
}

export function formatTotalAndRelativeScore(roundsNum: number, total?: number, rel?: number) {
    return formatTotalScore(roundsNum, total) + (total ? ' (' + formatRelativeScore(rel ?? 0) + ')' : '');
}

export type DiffName = 'eagle' | 'birdie' | 'par' | 'bogey' | 'dbogey';

export function getScoreDiffName(score?: number, par?: number) {
    if (!score || !par) {
        return;
    }
    const diff = score - par;
    if (diff <= -2) {
        return 'eagle';
    }
    switch (diff) {
        case -1:
            return 'birdie';
        case 0:
            return 'par';
        case 1:
            return 'bogey';
        default:
            return 'dbogey';
    }
}

export function mergeScores(onlyVerified: boolean, score?: Score, reportedScore?: ReportedScore, par?: Par[], noticePickUps?: boolean) {
    const gross = score?.gross;
    const pickUps = score?.pickUps;
    const strokes = reportedScore?.strokes;
    const reportedPickUps = reportedScore?.pickUps;
    const watched = reportedScore?.watched;
    const watchedPickUps = reportedScore?.watchedPickUps;
    const length = gross?.length ?? strokes?.length ?? 0;
    const consideredPickUps = new Array<boolean>(length).fill(false);
    return {
        gross: gross ? gross.map((grossScore, hole) => {
            if (noticePickUps && par && ((pickUps && ((pickUps[hole] && (onlyVerified || !watchedPickUps || !(watchedPickUps[hole] && reportedPickUps && !reportedPickUps[hole])))
                || (!onlyVerified && reportedPickUps && reportedPickUps[hole] && (!watchedPickUps || (watchedPickUps && !watchedPickUps[hole])))))
                || (!onlyVerified && (!pickUps || !pickUps[hole]) && (reportedPickUps && reportedPickUps[hole])))) {
                consideredPickUps[hole] = true;
                return getDoubleBogey(par[hole % par.length]);
            } else if (grossScore > 0) {
                return (!onlyVerified && strokes && watched && strokes[hole] && strokes[hole] !== watched[hole] && grossScore === watched[hole]) ? strokes[hole] : grossScore;
            } else if (!grossScore && !onlyVerified && strokes && strokes[hole] > 0) {
                return strokes[hole];
            }
            return 0;
        }) : onlyVerified ? new Array<number>(length).fill(0) : strokes ? strokes.map((stroke, hole) => {
            if (noticePickUps && par && reportedPickUps && reportedPickUps[hole]) {
                consideredPickUps[hole] = true;
                return getDoubleBogey(par[hole % par.length]);
            } else if (stroke > 0) {
                return stroke;
            }
            return 0;
        }) : new Array<number>(length).fill(0),
        pickUps: consideredPickUps
    } as Score;
}

function getSkinEligibility(score?: Score, holesType?: HolesType) {
    if (score && score.pickUps) {
        const holesRange: HolesRange = getHolesRange(holesType);
        const result = new Array<boolean>(18).fill(true, 0, holesRange.first).fill(false, holesRange.first);
        for (let hole = holesRange.first; hole < holesRange.last; ++hole) {
            result[hole] = !score.pickUps[hole];
        }
        return result;
    }
    return undefined;
}

export function golferScoringState(contactId: string, uniqueId: string, eventOrRound: EventBase, handicapSystem: HandicapSystem, playingHandicap: number, teeTime?: TeeTime, score?: Score, reportedScore?: ReportedScore, stablefordPoints?: Readonly<Array<number>>, tee?: Tee | null) {
    const holesType = eventOrRound.holesType;
    const holesRange = getHolesRange(holesType);
    const grossScore = mergeScores(eventOrRound.hideLiveScores === 'VERIFIED', score, reportedScore, tee?.par, true);
    const totalHoles = getTotalHoles(eventOrRound.holesType);
    const res: BaseScoringState = {
        event: eventOrRound,
        contactId,
        uniqueId,
        totalHoles,
        tee: tee || {} as Tee,
        teeTime,
        pos: '',
        courseHandicap: playingHandicap,
        score: grossScore.gross,
        nets: getNets(handicapSystem, playingHandicap, grossScore, reportedScore, holesType, tee),
        total: totalGross(grossScore, reportedScore, holesType),
        relativeTotal: relativeGrossTotal(grossScore, reportedScore, holesType, tee),
        stableford: relativeTotalStablefordPoints(grossScore, reportedScore, stablefordPoints, holesType, tee),
        stablefordNet: relativeTotalStablefordNetPoints(handicapSystem, playingHandicap, grossScore, reportedScore, stablefordPoints, holesType, tee),
        skinsEligibility: getSkinEligibility(score, holesType),
        net: totalNet(handicapSystem, playingHandicap, grossScore, reportedScore, holesType, tee),
        relativeNet: relativeNetTotal(handicapSystem, playingHandicap, grossScore, reportedScore, holesType, tee),
        holes: playedHoles(holesRange, grossScore, reportedScore, true),
        finalGrossScore: grossScore
    };
    return res;
}

export function bestBallReported(contacts: Array<string>, scores: Map<string, ReportedScore>, tees: Array<Tee | null | undefined>, holesType?: HolesType) {
    const res = Utils.array(MAX_HOLES, 0);
    contacts.map(id => scores.get(id))
        .forEach((score, hcpIndex) => {
            if (score) {
                updateBestBallReported(res, score, tees[hcpIndex], holesType);
            }
        });
    return { strokes: res } as ReportedScore;
}

export function bestBallGross(contacts: Array<string>, scores: Map<string, Score>, tees: Array<Tee | null | undefined>, holesType?: HolesType) {
    const res = Utils.array(MAX_HOLES, 0);
    contacts.map(id => scores.get(id))
        .filter(gross => !!gross)
        .forEach((gross, hcpIndex) => updateBestBallGross(res, gross!, tees[hcpIndex], holesType));
    return { gross: res } as Score;
}

export function bestBallNet(handicapSystem: HandicapSystem, contacts: Array<Contact>, playingHandicaps: Array<number>, scores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, tees: Array<Tee | null | undefined>, holesType?: HolesType) {
    const netScores = Utils.arrayUndef(MAX_HOLES);
    contacts.forEach((contact, hcpIndex) => updateBestBallNet(handicapSystem, netScores, playingHandicaps[hcpIndex], holesType, scores.get(contact.id), reportedScores.get(contact.id), tees[hcpIndex]));
    return netScores;
}

function updateBestBallGross(res: Array<number>, score: Score, tee: Tee | null | undefined, holesType?: HolesType) {
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        if (score.gross[hole] !== undefined) {
            const grossScore: number = (score.pickUps && score.pickUps[hole] && tee?.par) ? getDoubleBogey(tee.par[hole % tee.par.length]) : score.gross[hole];
            if (res[hole]) {
                if (grossScore) {
                    res[hole] = Math.min(res[hole], grossScore);
                }
            } else {
                res[hole] = grossScore;
            }
        }
    }
}

function updateBestBallReported(res: Array<number>, score: ReportedScore, tee: Tee | null | undefined, holesType?: HolesType) {
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        if (score.strokes && score.strokes[hole] !== undefined) {
            const reportedScore: number = (score.pickUps && score.pickUps[hole] && tee?.par) ? getDoubleBogey(tee.par[hole % tee.par.length]) : score.strokes[hole];
            if (res[hole]) {
                if (reportedScore) {
                    res[hole] = Math.min(res[hole], reportedScore);
                }
            } else {
                res[hole] = reportedScore;
            }
        }
    }
}

function updateBestBallNet(handicapSystem: HandicapSystem, netScores: Array<number | undefined>, playingHandicap: number, holesType?: HolesType, score?: Score, reportedScore?: ReportedScore, tee?: Tee | null) {
    const holesRange = getHolesRange(holesType);
    for (let hole = holesRange.first; hole < holesRange.last; hole++) {
        const net: number | undefined = scoreNet(handicapSystem, hole, playingHandicap, score, reportedScore, holesType, tee);
        if (netScores[hole]) {
            if (net) {
                netScores[hole] = Math.min(netScores[hole]!, net);
            }
        } else {
            netScores[hole] = net;
        }
    }
}

function getSkinsEligibility(contacts: string[], scores: Map<string, Score>, holesType?: HolesType) {
    if (scores.size > 0) {
        const holesRange: HolesRange = getHolesRange(holesType);
        const result = new Array<boolean>(18).fill(true, 0, holesRange.first).fill(false, holesRange.first);
        for (let hole = holesRange.first; hole < holesRange.last; ++hole) {
            for (const contact of contacts) {
                const score: Score | undefined = scores.get(contact);
                if (score && (!score.pickUps || !score.pickUps[hole])) {
                    result[hole] = true;
                    break;
                }
            }
        }
        return result;
    }
    return undefined;
}

export function bestBallScoringState(contactId: string, uniqueId: string, eventOrRound: EventBase, handicapSystem: HandicapSystem, contacts: Array<Contact>, playingHandicap: number, playingHandicaps: Array<number>, scores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, tees: Array<Tee | null | undefined>, teeTime?: TeeTime, stablefordPoints?: Readonly<Array<number>>) {
    const holesType = eventOrRound.holesType;
    const holesRange = getHolesRange(holesType);
    const contactIds = contacts.map(g => g.id);
    const gross = bestBallGross(contactIds, scores, tees, holesType);
    const reportedGross = bestBallReported(contactIds, reportedScores, tees, holesType);
    const tee = hardestTee(tees);
    const score = mergeScores(eventOrRound.hideLiveScores === 'VERIFIED', gross, reportedGross, tee?.par, false);
    const netScores = bestBallNet(handicapSystem, contacts, playingHandicaps, scores, reportedScores, tees, holesType);
    const reportedScore = undefined;
    const netScore = { gross: netScores } as Score;
    const totalHoles = getTotalHoles(eventOrRound.holesType);
    const res: BaseScoringState = {
        contactId,
        uniqueId,
        totalHoles,
        event: eventOrRound,
        tee: tee ?? {} as Tee,
        teeTime,
        pos: '',
        courseHandicap: playingHandicap,
        score: score.gross,
        nets: netScores,
        total: totalGross(score, reportedScore, holesType),
        relativeTotal: relativeGrossTotal(score, reportedScore, holesType, tee),
        stableford: relativeTotalStablefordPoints(score, reportedScore, stablefordPoints, holesType, tee),
        stablefordNet: relativeTotalStablefordNetPoints(handicapSystem, 0, netScore, reportedScore, stablefordPoints, holesType, tee),
        skinsEligibility: getSkinsEligibility(contacts.map(g => g.id), scores, holesType),
        net: totalNetBase(netScores, holesType),
        relativeNet: relativeNetTotalBase(netScores, holesType, tee),
        holes: playedHoles(holesRange, score, reportedScore, true),
        finalGrossScore: score
    };
    return res;
}

export function markAsReported(score?: Score, reportedScore?: ReportedScore) {
    if (!reportedScore || ((!reportedScore.strokes || reportedScore.strokes.every(value => !value)) &&
        (!reportedScore.pickUps || reportedScore.pickUps.every(pickUp => !pickUp)))) {
        return false;
    }
    if (!score) {
        return true;
    }
    for (let i = 0; i < reportedScore.strokes.length; i++) {
        if (markHoleAsReported(i, score, reportedScore)) {
            return true;
        }
    }
    return false;
}

export function markHoleAsReported(hole: number, scores?: Score, reported?: ReportedScore) {
    if (!reported || (!reported.strokes && !reported.pickUps) || (!reported.strokes[hole] && (reported.pickUps ? !reported.pickUps[hole] : true))) {
        return false;
    }
    if (!scores || (!scores.gross[hole] && (!scores.pickUps || !scores.pickUps[hole]))) {
        return true;
    }
    return (reported.strokes[hole] !== scores.gross[hole] && reported.watched && reported.watched[hole] === scores.gross[hole]) ||
        (reported.pickUps && reported.watchedPickUps && scores.pickUps && reported.pickUps[hole] !== scores.pickUps[hole] && scores.pickUps[hole] === reported.watchedPickUps[hole]);
}

export function golferHoleScore(player: Team | Contact, eventOrRound: EventBase, competition: Competition, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, golfers: Map<string, Contact>, groups: Array<GolferGroup>, teams: Array<Team>, competitionMain?: Competition, flight?: number, verifiedOnly?: boolean) {
    let golferScore: ContactScoringState;
    const sameNameGolfersIdsSet = getSameNameGolfersIds(Array.from(golfers.values()));
    const mainFormat = competitionMain ? competitionMain.scoring.format : '';
    const mainCompetition = (isSideGameScoring(competition.scoring) || isSkinsScoring(competition.scoring)) && competitionMain ? competitionMain : competition;
    const holesRange = getHolesRange(eventOrRound.holesType);
    verifiedOnly = verifiedOnly ?? eventOrRound.hideLiveScores === 'VERIFIED';
    if (competition.scoring.format === ScoringFormatTeams.best_ball || (competition.scoring.format === ScoringFormatSkins.skins_team && mainFormat === ScoringFormatTeams.best_ball)) {
        const team = player as Team;
        const group = groups.find(gr => gr.contactIds.indexOf(team.id) >= 0);
        const time = group ? getTeeTime(eventOrRound.teeTime, holesRange, group.order) : undefined;
        const contacts = golfersOfTeam(team, golfers);
        const tees = contacts.map(c => getTee(eventOrRound, competition, c.gender, c));
        const names = golferShortTeamNameArray(team, golfers, false, sameNameGolfersIdsSet);
        const handicapIndices = contacts.map(teamContact => getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, teamContact.handicapIndex || 0));
        const handicapAllowance: number[] = [];
        for (let i = 0; i < handicapIndices.length; i++) {
            handicapAllowance.push(getHandicapsAllowance(competition.scoring, i));
        }
        const teamPlayingHandicap = Math.round(handicapIndices.reduce((sum, hcp, i) => sum += getPlayingHandicap(eventOrRound.holesType, getTee(eventOrRound, mainCompetition, contacts[i].gender, contacts[i]), eventOrRound.handicapSystem, hcp, handicapAllowance[i]), 0));
        const playingHandicaps = handicapIndices.map((handicapIndex, index) => getPlayingHandicap(eventOrRound.holesType, getTee(eventOrRound, mainCompetition, contacts[index].gender, contacts[index]), eventOrRound.handicapSystem, handicapIndex, handicapAllowance[index]));
        // TODO check golferScoringState calculation is right with tee determination approach below
        const usedTee = hardestTee(tees);
        const handicapSystem = eventOrRound.handicapSystem ? eventOrRound.handicapSystem : (usedTee ? usedTee.handicapSystem : 'WHS');
        const isReported = team.contactIds.some(contactId => markAsReported(golferScores.get(contactId), reportedScores.get(contactId)));
        golferScore = {
            player,
            names,
            isReported,
            flight: flight ?? 0,
            mode: competition.scoring.mode,
            roundOrder: competition.roundOrder,
            contactInfo: {
                id: team.id,
                withdrawn: team.withdrawn,
                disqualified: team.disqualified,
                contacts,
                playingHandicaps
            },
            ...bestBallScoringState(team.id, contacts.map(c => c.id).join(), eventOrRound, handicapSystem, contacts, teamPlayingHandicap, playingHandicaps, golferScores, reportedScores, tees, time, getStablefordPoints(competition.scoring))
        };
    } else if (isTeamFormat(competition.scoring)) {
        const team = player as Team;
        const group = groups.find(gr => gr.contactIds.indexOf(team.id) >= 0);
        const time = group ? getTeeTime(eventOrRound.teeTime, holesRange, group.order) : undefined;
        const contacts = golfersOfTeam(team, golfers);
        const names = golferShortTeamNameArray(team, golfers, false, sameNameGolfersIdsSet);
        const handicapIndices = contacts.map((teamContact) => getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, teamContact.handicapIndex || 0));
        const handicapAllowance: number[] = [];
        for (let i = 0; i < handicapIndices.length; i++) {
            handicapAllowance.push(getHandicapsAllowance(competition.scoring, i));
        }
        const tees = contacts.map(c => getTee(eventOrRound, competition, c.gender, c));
        const usedTee = hardestTee(tees);
        const playingHandicaps = handicapIndices.map((handicapIndex, index) => getPlayingHandicap(eventOrRound.holesType, getTee(eventOrRound, mainCompetition, contacts[index].gender, contacts[index]), eventOrRound.handicapSystem, handicapIndex, handicapAllowance[index]));
        const teamPlayingHandicap = Math.round(playingHandicaps.reduce((sum, cur) => sum += cur, 0) / playingHandicaps.length);
        const handicapSystem = eventOrRound.handicapSystem ? eventOrRound.handicapSystem : (usedTee ? usedTee.handicapSystem : 'WHS');
        golferScore = {
            player,
            names,
            flight: flight ?? 0,
            mode: competition.scoring.mode,
            roundOrder: competition.roundOrder,
            contactInfo: {
                id: team.id,
                withdrawn: team.withdrawn,
                disqualified: team.disqualified,
                contacts,
                playingHandicaps,
                teamPlayingHandicap
            },
            isReported: markAsReported(teamScores.get(team.id), reportedTeamScores.get(team.id)),
            ...golferScoringState(team.id, contacts.map(c => c.id).join(), eventOrRound, handicapSystem, teamPlayingHandicap, time, teamScores.get(team.id), verifiedOnly ? undefined : reportedTeamScores.get(team.id), getStablefordPoints(competition.scoring), usedTee)
        };
    } else {
        const contact = Utils.cleanObj(player) as Contact;
        let group;
        if (isMainScoring(competition.scoring) && eventOrRound.teamSize > 1) {
            const golferTeam = teams.find(team => team.contactIds.indexOf(contact.id) >= 0);
            group = golferTeam ? groups.find(grp => grp.contactIds.indexOf(golferTeam.id) >= 0) : undefined;
        } else {
            group = groups.find(gr => gr.contactIds.indexOf(contact.id) >= 0);
        }
        const time = group ? getTeeTime(eventOrRound.teeTime, holesRange, group.order) : undefined;
        const name = fullName(contact, 15, 25).concat(sameNameGolfersIdsSet?.has(contact.id) && contact.homeCourseOrCity ? ` (${contact.homeCourseOrCity})` : '');
        const tee = getTee(eventOrRound, mainCompetition, contact.gender, contact);
        const playingHandicap = getPlayingHandicap(eventOrRound.holesType, tee, eventOrRound.handicapSystem, getGolferRangeHandicap(eventOrRound.holesType, eventOrRound.handicapSystem, contact.handicapIndex || 0), getHandicapsAllowance(competition.scoring, 0));
        const handicapSystem = eventOrRound.handicapSystem ? eventOrRound.handicapSystem : (tee ? tee.handicapSystem : 'WHS');
        golferScore = {
            player,
            names: [name],
            flight: flight ?? 0,
            mode: competition.scoring.mode,
            roundOrder: competition.roundOrder,
            contactInfo: {
                id: contact.id,
                withdrawn: contact.withdrawn,
                disqualified: contact.disqualified,
                handicapIndex: contact.handicapIndex,
                contacts: [contact],
                playingHandicaps: [playingHandicap]
            },
            isReported: markAsReported(golferScores.get(contact.id), reportedScores.get(contact.id)),
            ...golferScoringState(contact.id, contact.id, eventOrRound, handicapSystem, playingHandicap, time, golferScores.get(contact.id), verifiedOnly ? undefined : reportedScores.get(contact.id), getStablefordPoints(competition.scoring), tee)
        };
    }
    return golferScore;
}

export function golferHoleScores(eventOrRound: EventBase, competition: Competition, flight: number, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, mainCompetition?: Competition, verifiedOnly?: boolean) {
    const compareFunc = scoreCompare(competition);
    let scores: Array<ContactScoringState> = [];
    if (!competition.flights !== !flight) {
        return scores;
    }
    const teamsArray = Array.from<Team>(teams.values());
    if (!!competition.flights && competition.flights > 0 && flight > 0) {
        if (isTeamFormat(competition.scoring)) {
            teamsOfFlight(competition, golfers, teams, flight).forEach(team => scores.push(golferHoleScore(team, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, groups, teamsArray, mainCompetition, flight, verifiedOnly)));
        } else {
            contactsOfFlight(competition, golfers, teams, flight).forEach(contact => scores.push(golferHoleScore(contact, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, groups, teamsArray, mainCompetition, flight, verifiedOnly)));
        }
    } else {
        if (isTeamFormat(competition.scoring)) {
            teamsOf(competition, golfers, teams).forEach(team => scores.push(golferHoleScore(team, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, groups, teamsArray, mainCompetition, undefined, verifiedOnly)));
        } else {
            golfersOfCompetition(competition, golfers, teams).forEach(contact => scores.push(golferHoleScore(contact, eventOrRound, competition, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, groups, teamsArray, mainCompetition, undefined, verifiedOnly)));
        }
    }
    scores = scores.filter(scoringState => scoringState?.names?.length && scoringState?.contactInfo?.contacts?.length).sort(compareFunc);
    calcScoresPos(scores, competition);
    return scores;
}

function getCalculatedScores(eventOrRound: EventBase, competition: Competition, flight: number, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, mainCompetition?: Competition, verifiedOnly?: boolean): CalculatedFlightScores {
    const isGross = isGrossMode(competition.scoring.mode);
    const isNet = isNetMode(competition.scoring.mode);
    const totalHoles = getTotalHoles(eventOrRound.holesType);
    let bestGross = 1000;
    let bestNet = 1000;
    let flightWinnersGross: Array<ContactScoringState> = [];
    let flightWinnersNet: Array<ContactScoringState> = [];
    let flightScoresGross: Array<ContactScoringState> = [];
    let flightScoresNet: Array<ContactScoringState> = [];
    if (isNet) {
        const competitionNet = netCompetition(competition);
        flightScoresNet = golferHoleScores(eventOrRound, competitionNet, flight, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition, verifiedOnly);
        const flightWinners = flightScoresNet.filter(score => !score.contactInfo.withdrawn && !score.contactInfo.disqualified && score.holes === totalHoles);
        flightWinners.forEach(w => bestGross = bestGross > w.total ? w.total : bestGross);
        flightWinners.forEach(w => bestNet = bestNet > w.net ? w.net : bestNet);
        flightWinnersNet = flightWinners.filter(w => w.net === bestNet);
        flightScoresNet.forEach(score => {
            const winner = (competition.winners || []).filter(w => w.contactId === score.contactId);
            if (winner.length > 0 && score.holes === totalHoles && ((isGross && score.total === bestGross) || (isNet && score.net === bestNet))) {
                score.winnerIn = winner.map(w => w.mode);
            }
        });
    }
    if (isGross) {
        const competitionGross = grossCompetition(competition);
        flightScoresGross = golferHoleScores(eventOrRound, competitionGross, flight, golferScores, teamScores, reportedScores, reportedTeamScores, golfers, teams, groups, mainCompetition, verifiedOnly);
        const flightWinners = flightScoresGross.filter(score => !score.contactInfo.withdrawn && !score.contactInfo.disqualified && score.holes === totalHoles);
        flightWinners.forEach(w => bestGross = bestGross > w.total ? w.total : bestGross);
        flightWinners.forEach(w => bestNet = bestNet > w.net ? w.net : bestNet);
        flightWinnersGross = flightWinners.filter(w => w.total === bestGross);
        flightScoresGross.forEach(score => {
            const winner = (competition.winners || []).filter(w => w.contactId === score.contactId);
            if (winner.length > 0 && score.holes === totalHoles && ((isGross && score.total === bestGross) || (isNet && score.net === bestNet))) {
                score.winnerIn = winner.map(w => w.mode);
            }
        });
    }
    return { flightScoresGross, flightScoresNet, flightWinnersGross, flightWinnersNet };
}

export function getGolferScores(event: EventBase, competitions: Array<Competition>, golfers: Map<string, Contact>, teams: Map<string, Team>, groups: Array<GolferGroup>, golferScores: Map<string, Score>, teamScores: Map<string, Score>, reportedScores: Map<string, ReportedScore>, reportedTeamScores: Map<string, ReportedScore>, mainCompetition?: Competition, verifiedOnly?: boolean) {
    const calculatedScores = new Map<string, CalculatedScores>();
    competitions.forEach(competition => {
        const competitionScoresGross = new Map<number, Array<ContactScoringState>>();
        const competitionScoresNet = new Map<number, Array<ContactScoringState>>();
        const competitionWinnersGross = new Map<number, Array<ContactScoringState>>();
        const competitionWinnersNet = new Map<number, Array<ContactScoringState>>();
        if (isMainScoring(competition.scoring)) {
            getFlights(competition).forEach(flight => {
                const { flightScoresGross, flightScoresNet, flightWinnersGross, flightWinnersNet } = getCalculatedScores(event, competition, flight, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, mainCompetition, verifiedOnly);
                competitionScoresGross.set(flight, flightScoresGross);
                competitionScoresNet.set(flight, flightScoresNet);
                competitionWinnersGross.set(flight, flightWinnersGross);
                competitionWinnersNet.set(flight, flightWinnersNet);
            });
        }
        const calculatedScore = calculatedScores.get(competition.id);
        if (calculatedScore) {
            const isNet = isNetMode(competition.scoring.mode);
            if (isNet) {
                calculatedScore.competitionScoresNet = competitionScoresNet;
                calculatedScore.competitionWinnersNet = competitionWinnersNet;
            } else {
                calculatedScore.competitionScoresGross = competitionScoresGross;
                calculatedScore.competitionWinnersGross = competitionWinnersGross;
            }
            calculatedScores.set(competition.id, calculatedScore);
        } else {
            calculatedScores.set(competition.id, { competitionScoresGross, competitionScoresNet, competitionWinnersGross, competitionWinnersNet });
        }
    });
    return calculatedScores;
}

export function getContactStandings(eventOrRounds: Array<Event | Round>, competitions: Array<Competition>, golfersMap: Map<string, Map<string, Contact>>, teamsMap: Map<string, Map<string, Team>>, groups: Array<GolferGroup>, golferScoresMap: Map<string, Map<string, Score>>, teamScoresMap: Map<string, Map<string, Score>>, reportedScoresMap: Map<string, Map<string, ReportedScore>>, reportedTeamScoresMap: Map<string, Map<string, ReportedScore>>, contact?: Contact, scoredContactIds?: Array<string>): Array<ContactScoringState> | undefined {
    const calculatedScoresMap = new Map<string, CalculatedFlightScores>();
    for (const eventOrRound of eventOrRounds) {
        const competition = eventOrRound.appCompetition;
        if (!competition) {
            continue;
        }
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const golferScores = golferScoresMap.get(eventOrRound.id) ?? new Map<string, Score>();
        const teamScores = teamScoresMap.get(eventOrRound.id) ?? new Map<string, Score>();
        const reportedScores = reportedScoresMap.get(eventOrRound.id) ?? new Map<string, ReportedScore>();
        const reportedTeamScores = reportedTeamScoresMap.get(eventOrRound.id) ?? new Map<string, ReportedScore>();
        let calculatedScores: CalculatedFlightScores;
        if (competition.flights && scoredContactIds) {
            calculatedScores = new CalculatedFlightScores();
            const flightNumbers = getFlightNumbersForIds(competition, golfers, teams, new Set<string>(scoredContactIds));
            if (flightNumbers) {
                const flightNumbersSorted = Array.from<number>(flightNumbers).sort((n1, n2) => n1 - n2);
                for (const flightInd of flightNumbersSorted) {
                    const { flightScoresGross, flightScoresNet } = getCalculatedScores(eventOrRound, competition, flightInd, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, competition);
                    calculatedScores.flightScoresGross.push(...flightScoresGross);
                    calculatedScores.flightScoresNet.push(...flightScoresNet);
                }
            }
        } else {
            const flight = scoredContactIds || !contact ? 0 : getFlightOfContact(competition, golfers, teams, contact) ?? 0;
            calculatedScores = getCalculatedScores(eventOrRound, competition, flight, golfers, teams, groups, golferScores, teamScores, reportedScores, reportedTeamScores, competition);
        }
        calculatedScoresMap.set(eventOrRound.id, calculatedScores);
    }
    if (eventOrRounds[0].appCompetition) {
        const compareFunc = scoreCompare(eventOrRounds[0].appCompetition);
        const totalScores = mergeRoundsScores(calculatedScoresMap, compareFunc, eventOrRounds[0].appCompetition);
        if (totalScores?.flightScoresNet.length > 0) {
            return totalScores.flightScoresNet;
        }
        if (totalScores?.flightScoresGross.length > 0) {
            return totalScores.flightScoresGross;
        }
        Utils.dbgLog('Error. No calculated scores.');
        return undefined;
    } else {
        Utils.dbgLog('Error. Cant find main appCompetition for the multiday event.');
        return undefined;
    }
}

function mergeRoundsScores(roundsScoresMap: Map<string, CalculatedFlightScores>, compareFunc: (c1: ContactScoringState, c2: ContactScoringState) => number, competition?: Competition | null) {
    if (roundsScoresMap.size === 1) {
        return roundsScoresMap.values().next().value;
    } else {
        const result: CalculatedFlightScores = Array.from<CalculatedFlightScores>(roundsScoresMap.values())
            .reduce((accumulatedFlightScores, calculatedFlightScores) => mergeCalculatedFlightScores(accumulatedFlightScores, calculatedFlightScores, compareFunc, competition), new CalculatedFlightScores());
        return result;
    }
}

function mergeCalculatedFlightScores(accumulatedFlightScores: CalculatedFlightScores, calculatedFlightScores: CalculatedFlightScores, compareFunc: (c1: ContactScoringState, c2: ContactScoringState) => number, competition?: Competition | null) {
    const result: CalculatedFlightScores = { ...accumulatedFlightScores };
    if (calculatedFlightScores.flightScoresGross.length > 0) {
        if (result.flightScoresGross.length === 0) {
            result.flightScoresGross = [...calculatedFlightScores.flightScoresGross];
        } else {
            const scores = result.flightScoresGross.map(getContactScoringStateMerger(calculatedFlightScores.flightScoresGross)).sort(compareFunc);
            calcScoresPos(scores, competition);
            result.flightScoresGross = [...scores];
        }
    }
    if (calculatedFlightScores.flightScoresNet.length > 0) {
        if (result.flightScoresNet.length === 0) {
            result.flightScoresNet = [...calculatedFlightScores.flightScoresNet];
        } else {
            const scores = result.flightScoresNet.map(getContactScoringStateMerger(calculatedFlightScores.flightScoresNet)).sort(compareFunc);
            calcScoresPos(scores, competition);
            result.flightScoresNet = [...scores];
        }
    }
    result.flightWinnersGross = [];
    result.flightWinnersNet = [];
    return result;
}

function placeholderScore(roundOrder: number, refScore: ContactScoringState) {
    const m: ContactScoringState = {
        roundOrder,
        event: {} as EventBase,
        tee: {} as Tee,
        totalHoles: 0,
        total: 0,
        relativeTotal: 0,
        net: 0,
        relativeNet: 0,
        stableford: 0,
        stablefordNet: 0,
        holes: 0,
        courseHandicap: 0,
        pos: '?',
        finalGrossScore: { id: '', gross: [] },
        contactId: refScore.contactId,
        uniqueId: refScore.uniqueId,
        contactInfo: refScore.contactInfo,
        player: refScore.player,
        mode: refScore.mode,
        names: refScore.names,
    };
    return m;
}

export function getCompetitionScores(roundsCompetitions: Array<Competition>, flight: number, calculatedScoresMap: Map<string, Map<string, CalculatedScores>>) {
    return roundsCompetitions.map(competition => {
        const isNet = isNetMode(competition.scoring.mode);
        const competitionScores = calculatedScoresMap.get(competition.eventOrRoundId ?? '')?.get(competition.id);
        return (isNet ? competitionScores?.competitionScoresNet : competitionScores?.competitionScoresGross)?.get(flight) ?? [];
    });
}

export function combineCompetitionScores(roundsCompetitions: Array<Competition>, flight: number, calculatedScoresMap: Map<string, Map<string, CalculatedScores>>) {
    const roundsScores = getCompetitionScores(roundsCompetitions, flight, calculatedScoresMap);
    if (roundsScores.length > 0 && roundsScores[0].length > 0) {
        for (let i = 1; i < roundsScores.length; i++) {
            const emptyScores = roundsScores[i]
                .filter(rx => !roundsScores[0].some(ry => rx.uniqueId === ry.uniqueId))
                .map(refScore => placeholderScore(1, refScore));
            roundsScores[0].splice(roundsScores[0].length, 0, ...emptyScores);
        }
        for (let i = 1; i < roundsScores.length; i++) {
            const emptyScores = roundsScores[0]
                .filter(rx => !roundsScores[i].some(ry => rx.uniqueId === ry.uniqueId))
                .map(refScore => placeholderScore(i + 1, refScore));
            roundsScores[i].splice(roundsScores[i].length, 0, ...emptyScores);
        }
    }
    return roundsScores;
}

export function contactRoundsScoresOf(uniqueId: string, roundsScores: ContactScoringState[][]): ContactRoundsScores {
    const scoringStates = roundsScores.map(roundsScore => roundsScore.find(score => score.uniqueId === uniqueId));
    const contactId = scoringStates[0]?.contactId ?? '';
    let total = 0;
    let holes = 0;
    let totalHoles = 0;
    let relativeTotal = 0;
    let net = 0;
    let relativeNet = 0;
    let stableford = 0;
    let stablefordNet = 0;
    scoringStates.forEach(score => {
        if (!score) {
            return;
        }
        total += score.total ?? 0;
        holes += score.holes ?? 0;
        totalHoles += score.totalHoles ?? 0;
        relativeTotal += score.relativeTotal ?? 0;
        net += score.net ?? 0;
        relativeNet += score.relativeNet ?? 0;
        stableford += score.stableford ?? 0;
        stablefordNet += score.stablefordNet ?? 0;
    });
    const pos = '??';
    const withdrawn = !!scoringStates.find(s => s?.contactInfo.withdrawn);
    const disqualified = !!scoringStates.find(s => s?.contactInfo.disqualified);
    const player = scoringStates[0]!.player;
    const names = scoringStates[0]!.names;
    return {
        player,
        names,
        contactId,
        uniqueId,
        scoringStates,
        pos,
        holes,
        totalHoles,
        total,
        relativeTotal,
        net,
        relativeNet,
        stableford,
        stablefordNet,
        withdrawn,
        disqualified
    };
}

export function contactsWithRoundsScoresOf(contactUniqueIds: Array<string>, roundsScores: ContactScoringState[][], competition: Competition) {
    const compareFunc = scoreCompareRounds(competition);
    const contactsWithRoundsScores = contactUniqueIds.map(contactUniqueId => contactRoundsScoresOf(contactUniqueId, roundsScores)).sort(compareFunc);
    calcScoresPos(contactsWithRoundsScores, competition);
    return contactsWithRoundsScores;
}

function getContactScoringStateMerger<T extends BaseScoringInfo>(calculatedScores: Array<T>) {
    return (contactScore: T) => {
        const calculatedScore = calculatedScores.find(score => contactScore.uniqueId === score.uniqueId);
        if (calculatedScore) {
            calculatedScore.stableford += (contactScore.holes > 0 ? contactScore.stableford : 0);
            calculatedScore.stablefordNet += (contactScore.holes > 0 ? contactScore.stablefordNet : 0);
            calculatedScore.relativeTotal += (contactScore.holes > 0 ? contactScore.relativeTotal : 0);
            calculatedScore.relativeNet += (contactScore.holes > 0 ? contactScore.relativeNet : 0);
            calculatedScore.total += (contactScore.holes > 0 ? contactScore.total : 0);
            calculatedScore.net += (contactScore.holes > 0 ? contactScore.net : 0);
            calculatedScore.holes += contactScore.holes;
            calculatedScore.totalHoles = (calculatedScore.totalHoles ?? 0) + (contactScore.totalHoles ?? 0);
            return calculatedScore;
        } else {
            return contactScore;
        }
    };
}

export function netCompetition(competition: Competition): Competition {
    const { scoring, ...competitionData } = competition;
    const { mode, ...scoringData } = competition.scoring;
    const fromBothModes = isNetMode(mode) && isGrossMode(mode);
    return { scoring: { mode: 'net', ...scoringData }, fromBothModes, ...competitionData };
}

export function grossCompetition(competition: Competition): Competition {
    const { scoring, ...competitionData } = competition;
    const { mode, ...scoringData } = competition.scoring;
    const fromBothModes = isNetMode(mode) && isGrossMode(mode);
    return { scoring: { mode: 'gross', ...scoringData }, fromBothModes, ...competitionData };
}

export const defaultStablefordPoints = Object.freeze([0, 1, 2, 3, 4, 5, 6]);

export function getStablefordPoints(scoring: ScoringData) {
    if (isStablefordScoringOrMode(scoring)) {
        return scoring.mstablefordPoints && scoring.mstablefordPoints.length > 0 ? scoring.mstablefordPoints : defaultStablefordPoints;
    }
    return [];
}

export function getMonitoringReportedScoresArray(reportedScores: Map<string, ReportedScore>, totalHoles: number, bestBall: boolean, scoringStates: ContactScoringState) {
    const reportedScore = reportedScores.get(scoringStates.contactId);
    const contactInfo = scoringStates.contactInfo;
    return bestBall ? [
        ...contactInfo.contacts.map(contact => reportedScores.get(contact.id)?.strokes ?? new Array<number>(totalHoles).fill(0))
            .reduce((prev, cur) => prev.concat(cur), []),
        ...contactInfo.contacts.map(contact => reportedScores.get(contact.id)?.pickUps ?? new Array<boolean>(totalHoles).fill(false))
            .reduce((prev, cur) => prev.concat(cur), [])
    ] : [
        ...(reportedScore?.strokes ?? new Array<number>(totalHoles).fill(0)),
        ...(reportedScore?.pickUps ?? new Array<boolean>(totalHoles).fill(false))
    ];
}
