import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from "../../../styles";
import { Competition, isDistanceScoring, isMainScoring, isSideGameScoring, ScoringFormatIndividual } from "../../../types/EventTypes";
import { XSMobileDialog } from "../../../common/dialog/MobileDialog";
import DialogAppBar from "../../../common/dialog/DialogAppBar";
import { DialogActions, Typography } from "@mui/material";
import { equalArrays, makeFriendlyString } from "../../../util/utility";
import AppButton from "../../../common/components/AppButton";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const dragHandleIcon = '/img/drag-handle-blue.png';

type CompetitionsListProps = {
    headerName: string,
    competitions: Competition[],
    classes: Record<string, string>;
    onCompetitionsOrderChanged: (competitions: Competition[]) => void;
};

const onDragEnd = (result: DropResult, competitions: Competition[]): void => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;
    if (destinationIndex != null && sourceIndex !== destinationIndex) {
        competitions.splice(destinationIndex, 0, competitions.splice(sourceIndex, 1)[0]);
    }
}

const DraggableCompetitionsList = (props: CompetitionsListProps) => {
    const { classes, headerName, competitions, onCompetitionsOrderChanged } = props;
    return <>
        <DragDropContext onDragEnd={(result: DropResult) => {
            onDragEnd(result, competitions);
            onCompetitionsOrderChanged(competitions);
        }}>
            <Typography
                className={`${classes.changeDisplayOrder} ${classes.paddingLeftRight16} ${classes.competitionsOrSideGames}`}>{headerName}</Typography>
            <Droppable droppableId={headerName}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {competitions.map((competition, index) => <div key={competition.id}>
                            <Draggable draggableId={competition.id} index={index}>
                                {(provided, snapshot) => {
                                    const format = competition.scoring.format === ScoringFormatIndividual.modified_stableford ?
                                        ScoringFormatIndividual.stableford : competition.scoring.format;
                                    return (<div ref={provided.innerRef}
                                        className={`${classes.changeDisplayOrder} ${classes.marginLeftRight16} ${classes.draggableElement}`}
                                        {...provided.draggableProps}
                                        style={{
                                            ...provided.draggableProps.style,
                                            boxShadow: snapshot.isDragging ? '0 0 .2rem #025987' : 'none'
                                        }}
                                        {...provided.dragHandleProps}>
                                        <img className={classes.dragHandleIcon} src={dragHandleIcon} alt={''} />
                                        <Typography style={{ marginLeft: 28 }}>
                                            {`${competition.name && competition.name.length > 0 ? competition.name :
                                                makeFriendlyString(`${format + (isDistanceScoring(competition.scoring)
                                                    ? '' : (', ' + competition.scoring.mode))}`, true)}`}
                                            order {competition.order}
                                        </Typography>
                                    </div>);
                                }}
                            </Draggable>
                        </div>)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    </>;
}

type ReorderCompetitionsProps = {
    competitions: Competition[];
    open: boolean;
    handleClose: () => void;
    handleSave: (reorderedCompetitions: Competition[]) => void;
};

type ReorderCompetitionsState = {
    mainCompetitions: Competition[];
    sideCompetitions: Competition[];
};

class ReorderCompetitionsDialog extends React.Component<ReorderCompetitionsProps & WithStyles<typeof styles>, ReorderCompetitionsState> {
    state: ReorderCompetitionsState = {
        mainCompetitions: this.props.competitions.filter(competition => isMainScoring(competition.scoring)),
        sideCompetitions: this.props.competitions.filter(competition => isSideGameScoring(competition.scoring))
    }

    private readonly initialOrder: Array<number> = this.props.competitions.map(competition => competition.order);
    private readonly onSideCompetitionsOrderChanged = (sideCompetitions: Competition[]) => this.setState({ sideCompetitions });
    private readonly onMainCompetitionsOrderChanged = (mainCompetitions: Competition[]) => this.setState({ mainCompetitions });

    render() {
        const { handleClose, handleSave, open, classes } = this.props;
        const { mainCompetitions, sideCompetitions } = this.state;
        const reorderedCompetitions = mainCompetitions.concat(sideCompetitions);
        const currentOrder = reorderedCompetitions.map(c => c.order);
        return <XSMobileDialog open={open} onClose={handleClose}>
            <DialogAppBar label={'Display order'} close={handleClose} />
            <Typography className={`${classes.changeDisplayOrder} ${classes.paddingLeftRight16}`}>
                Drag and drop to change competitions display order.
            </Typography>
            {mainCompetitions.length > 0 && <DraggableCompetitionsList
                competitions={mainCompetitions}
                headerName={'Competitions'}
                classes={classes}
                onCompetitionsOrderChanged={this.onMainCompetitionsOrderChanged} />}
            {sideCompetitions.length > 0 && <DraggableCompetitionsList
                competitions={sideCompetitions}
                headerName={'Side games'}
                classes={classes}
                onCompetitionsOrderChanged={this.onSideCompetitionsOrderChanged} />}
            <DialogActions>
                <AppButton color="info" onClick={handleClose}>Cancel</AppButton>
                <AppButton color="secondary" onClick={() => handleSave(reorderedCompetitions)}
                    disabled={equalArrays<number>(this.initialOrder, currentOrder)}>Save</AppButton>
            </DialogActions>
        </XSMobileDialog>
    }
}

export default withStyles(styles)(ReorderCompetitionsDialog);
